<template>
  <div class="flex flex-wrap mt-4 table-main">
    <Notify :showNotice="showNotice" :messErr="messErr" :messageNotice="messageNotice"></Notify>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
         :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center" style="justify-content: space-between;">
          <div class="relative px-4 max-w-full">
            <h3 class="font-semibold text-lg" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
              {{$route.params.id ? 'Chỉnh sửa ' : 'Thêm mới '}}thông tin người dùng
            </h3>
          </div>
          <div class="relative px-4 max-w-full">
            <a
                class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                href="/admin/account/index"
            >
              <i class="fa fa-arrow-left"></i> Quay lại
            </a>
          </div>
        </div>
      </div>
      <div class="block w-full overflow-x-auto">
        <div class="ml-4 mb-2">
          <div
               class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none blur-form">
            <div class="flex-auto px-4 lg:px-10 pt-10 pt-0">
              <form>
                <div class="flex flex-wrap p-4">
                  <div class="w-full px-3">
                    <div class="relative w-full mb-3">
                      <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                        Tên người dùng
                      </label>
                      <input type="text"
                             class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                             v-model="user.name" placeholder="Điền tên tài khoản" style="margin-bottom: 6px;" :maxlength="maxCharacters" @input="removeSpaces"/>
                      <span v-if="v$.user.name.$error" style="color:red"> {{ v$.user.name.$errors[0].$message }} </span>
                    </div>
                  </div>
                  <div class="w-full px-4">
                    <div class="relative w-full mb-3">
                      <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                        Email
                      </label>
                      <input type="text"
                             class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                             v-model="user.email" placeholder="Email" style="margin-bottom: 6px;"/>
                      <span v-if="v$.user.email.$error" style="color:red"> {{ v$.user.email.$errors[0].$message }} </span>
                    </div>
                  </div>
                  <div class="w-full px-4">
                    <div class="relative w-full mb-3">
                      <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                        Trạng thái
                      </label>
                      <select name="selectStatus" v-model="user.is_active"
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                        <option value="1">Kích hoạt</option>
                        <option value="0">Ngừng kích hoạt</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <hr class="mb-8">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <h4 class="font-semibold text-lg mb-3" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
                Quản lý vai trò
              </h4>
              <!-- Lặp qua các role -->
              <div v-for="(role, index) in roles" :key="role.id" class="role-block p-4">
                <div class="role-header">
                  <label class="role-label">
                    <input
                        type="checkbox"
                        v-model="role.selected"
                        @change="onRoleChange(role)"
                    />
                    {{ role.name }} - {{ role.description }}
                  </label>
                  <!-- Nút đóng mở -->
                  <button @click.stop="toggleRole(index)" class="toggle-button">
                    <i :class="expandedRoles.includes(index) ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                  </button>
                </div>

                <!-- Nội dung của role, chỉ hiển thị khi role được mở -->
                <transition name="slide-fade">
                  <div v-if="expandedRoles.includes(index)" class="permissions-list">
                    <div v-for="permission in role.permissions" :key="permission.id" class="permission-item">
                      <label class="permission-name">
<!--                        <input-->
<!--                            type="checkbox"-->
<!--                            v-model="permission.selected"-->
<!--                            @change="onPermissionChange(role, permission)"-->
<!--                            :disabled="role.selected"-->
<!--                        />-->
                        {{ permission.name }} - {{ permission.description }}
                      </label>
                    </div>
                  </div>
                </transition>
              </div>
            </div>

            <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b p-4">
              <button
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button" v-on:click="submitChanges">
                Đồng ý
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Định dạng các khối chính */
.role-block {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

/* Định dạng tiêu đề của role */
.role-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
}

/* Định dạng checkbox và nhãn */
.role-label {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.role-label input {
  margin-right: 10px;
}

/* Định dạng nút toggle (mở/đóng permissions) */
.toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

/* Danh sách permissions */
.permissions-list {
  padding-left: 20px;
  margin-top: 10px;
}

/* Định dạng mỗi item permission */
.permission-item {
  margin-bottom: 5px;
}

/* Hiệu ứng mở/đóng */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active trong <2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}

.permission-name {
  font-size: 12px;
}
</style>

<script>
import apiClient from "@/services/api";
import Notify from "@/components/Notification/Notify.vue";
import useValidate from '@vuelidate/core'
import { required, minLength, helpers, email } from '@vuelidate/validators'
import {toast} from "vue3-toastify";

const alphaValidator = value => /^[a-zA-Z0-9àáảãạăắằẳẵặâấầẩẫậèéẻẽẹêếềểễệđìíỉĩịòóỏõọôốồổỗộơớờởỡợùúủũụưứừửữựỳýỷỹỵ ]+$/.test(value);

export default {
  components: {Notify},
  data() {
    return {
      v$: useValidate(),
      roles: [],
      user: {
        name:'',
        email: '',
        is_active: 0
      },
      messageNotice: '',
      messErr: false,
      showNotice: false,
      maxCharacters: 50,
      expandedRoles: [],
    };
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    toggleRole(index) {
      // Kiểm tra xem role đã mở hay chưa, nếu mở rồi thì đóng lại
      if (this.expandedRoles.includes(index)) {
        this.expandedRoles = this.expandedRoles.filter((i) => i !== index);
      } else {
        this.expandedRoles.push(index);
      }
    },
    removeSpaces() {
      // Loại bỏ dấu cách từ giá trị đầu vào
      this.user.name = this.user.name.replace(/\s/g, "");
    },
    fetchData() {
      apiClient.get('api/permission-manager/role/user/' + this.$route.params.id)
          .then(response => {
            if (response.data.error_code !== 0) {
              toast(response?.data?.message || "Đã có lỗi xảy ra!", {
                "type": "error",
                "transition": "flip",
                "dangerouslyHTMLString": true
              })
            } else {
              this.roles = response.data.data.permissions;
              this.user = response.data.data.user;
            }

            setTimeout(() => {
              this.showNotice = false;
              this.messErr = false;
            }, 2000);
          })
          .catch(error => {
            toast(error?.response?.data?.message || "Đã có lỗi xảy ra!", {
              "type": "error",
              "transition": "flip",
              "dangerouslyHTMLString": true
            })
            console.error('Đã có lỗi xảy ra!', error);
          });
    },
    submitChanges() {
      const selectedRoles = this.roles.filter(role => role.selected).map(role => role.id);

      // const selectedPermissions = this.roles
      //     .filter(role => !role.selected)
      //     .flatMap(role => role.permissions)
      //     .filter(permission => permission.selected)
      //     .map(permission => permission.id);

      apiClient.put('api/permission-manager/role/user/' + this.$route.params.id, {roles: selectedRoles, permissions: {}, users: {
          name:this.user.name,
          email: this.user.email,
          is_active: this.user.is_active
        }})
          .then(response => {
            if (response.data.error_code !== 0) {
              toast(response?.data?.message || "Đã có lỗi xảy ra!", {
                "type": "error",
                "transition": "flip",
                "dangerouslyHTMLString": true
              })
            } else {
              this.showNotice = true;
              this.messageNotice = response.data.message || 'Cập nhật thành công'
              if (this.$route.params.id) {
                this.fetchData();
              } else {
                window.location.href = '/admin/account/permission/' + response.data.data.user.id;
              }
            }

            setTimeout(() => {
              this.showNotice = false;
              this.messErr = false;
            }, 2000);
          })
          .catch(error => {
            toast(error?.response?.data?.message || "Đã có lỗi xảy ra!", {
              "type": "error",
              "transition": "flip",
              "dangerouslyHTMLString": true
            })
            console.error('Đã có lỗi xảy ra!', error);
          });
    },
    onRoleChange(role) {
      role.permissions.forEach(permission => {
        permission.selected = role.selected;
      });
    },

    onPermissionChange(role, permission) {
      if (!permission.selected) {
        role.selected = false;
      }
    },
  },
  validations() {
    return {
      user: {
        name: {
          required: helpers.withMessage('Vui lòng nhập tên admin', required),
          minLength: helpers.withMessage('Vui lòng nhập từ 5->255 ký tự', minLength(2)),
          alphaValidator: helpers.withMessage('Chỉ chấp nhận ký tự chữ cái', value => alphaValidator(value)),
        },
        email: {
          required: helpers.withMessage('Vui lòng nhập email', required),
          email: helpers.withMessage('Định dạng email không đúng', email)
        },
      },
    }
  },
};
</script>
