import authService from "@/services/authService";
import WebStorage from '@/services/webStorage'

const webLocalStorage = new WebStorage('local')

const refreshFail = () => {
    webLocalStorage.remove('sdk_token')
    webLocalStorage.remove('sdk_refresh_token')
    webLocalStorage.remove('user_info')
    // location.reload()
    window.location.href = '/auth/login'
}

export const handleError = async (error) => {
    const { status } = error.response
    switch (status) {
        case 400:
            break
        case 401:
            if (!webLocalStorage.get('sdk_refresh_token') || typeof webLocalStorage.get('sdk_refresh_token') !== "number") {
                webLocalStorage.set('sdk_refresh_token', 0)
            }

            if (parseInt(webLocalStorage.get('sdk_refresh_token')) < 1) {
                webLocalStorage.set('sdk_refresh_token', 1)
                // Redirect to log in page if unauthenticated
                try {
                    const { data } = await authService.refresh({
                        refresh_token: webLocalStorage.get('sdk_token')?.refresh_token,
                    })
                    if (data.data?.access_token) {
                        webLocalStorage.set('sdk_token', {
                            access_token: data.data.access_token,
                            refresh_token: data.data.refresh_token
                        })
                        webLocalStorage.set('sdk_refresh_token', 0)
                        location.reload()
                        break
                    }
                } catch (error) {
                    console.log('Refresh token fail', error)
                    refreshFail()
                }
            }

            refreshFail()
            break
        case 402:
            break
        case 422:
            try {
                return error.response
            } catch (e) { /* empty */ }
            break
        case 404:
            break
        case 405:
            break
        case 419:
            break
        case 500:
            break
        default:
            break
    }
    return Promise.reject(error)
}