<template>
  <div>
  <Notify :showNotice = "showNotice" :messErr = "messErr" :messageNotice="messageNotice"></Notify>
  <div class="flex flex-wrap mt-4 table-main">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">

      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center justify-between">
          <div class="relative px-4 max-w-full">
            <h3 class="font-semibold text-lg" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
              Quản lý thông tin User
            </h3>
          </div>

          <!-- Container chứa trường tìm kiếm và các nút -->
          <div class="flex items-center space-x-4 ml-auto">
            <!-- Trường tìm kiếm -->
            <div class="relative">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Tìm kiếm user
              </label>
              <input
                  placeholder=" Tìm kiếm user theo tên hoặc email"
                  type="text"
                  lang="vi"
                  v-model="searchValue"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                  style="width: 250px; margin-right: 50px"
              />
            </div>

            <!-- Nút Tìm kiếm -->
            <button style="margin: 20px 50px 0 0" @click="handleSearch" class="hover:bg-emerald-600 bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-3 rounded shadow outline-none focus:outline-none ease-linear transition-all duration-150" type="button">
              <i class="fas fa-search"></i> Tìm kiếm
            </button>

            <!-- Nút Thêm mới -->
            <a style="margin: 20px 50px 0 0"
                class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button" href="/admin/account/permission/">
              <i class="fas fa-plus"></i> Thêm mới
            </a>
          </div>
        </div>
      </div>

      </div>
      <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">
                STT
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">
                Tên người dùng
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">
                Email
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">
                Trạng thái
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">
              </th>
            </tr>
          </thead>
          <tbody>
          <tr :style="{backgroundColor: index % 2 === 0 ? '#ecf0f5' : ''}" v-bind:key="index" v-for="(item, index) in dataTable">
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                <span class="ml-3 font-bold" :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white',
                ]">
                  {{ index + 1 }}
                </span>
              </th>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ item.name }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ item.email }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <span v-if="item.is_active == 1"><i class="fas fa-circle text-emerald-500 mr-2"></i> Kích hoạt</span>
                <span v-if="item.is_active == 0"><i class="fas fa-circle text-orange-500 mr-2"></i> Không kích hoạt</span>
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
<!--                <button-->
<!--                  class="text-lightBlue-500 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"-->
<!--                  type="button" v-on:click="toggleModal('create', item.id)">-->
<!--                  <i class="fas fa-edit" style="font-size: 18px;"></i>-->
<!--                </button>-->
                <a
                    class="text-lightBlue-500 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    :href="'/admin/account/permission/' + item.id">
                  <i class="fas fa-edit" style="font-size: 18px;"></i>
                </a>
                <button
                  class="text-red-500 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button" v-on:click="deleteData(item.id)">
                  <i class="fas fa-trash" style="font-size: 18px;"></i>
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot style="height: 50px;">
              <Panigation :dataPage="dataUser" @pageChange:index="fetchData"/>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <div>
    <formAdmin v-if="showModal" :activeForm="showModal" @update:activeForm="updateActiveForm" :typeForm="form" :idData="itemId">
    </formAdmin>
  </div>
</template>
<style>
.table-main table tbody {
  font-size: 22px;
  font-weight: 500;
}

.main-boss {
  display: grid;
  grid-template-rows: 0fr;
}

.overlay-1 {
  opacity: 0.3;
  transition: opacity 0.3s ease;
}

.overlay-2 {
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
</style>
<script>
import Panigation from "@/components/Panigation.vue";
import formAdmin from './formAdmin.vue';
import apiClient from '@/services/api.js';

import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";

import Notify from "@/components/Notification/Notify.vue"
import {toast} from "vue3-toastify";


export default {
  data() {
    return {
      bootstrap,
      angular,
      sketch,
      react,
      vue,
      showModal: false,
      dataTable: [],
      dataUser: [],
      form: '',
      itemId: '',
      progress: 0, // Giả sử bạn có một biến progress để theo dõi tiến trình
      limit: 10,
      page:null,
      messageNotice: '',
      messErr: false,
      showNotice: false,
      searchValue: null,
    };
  },
  components: {
    formAdmin,
    Panigation,
    Notify
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    // Call api xóa dữ liệu bảng
    async deleteData(id) {
      if (confirm("Bạn có chắc xóa thông tin này không?")) {
        await apiClient.delete('api/cms/admin/delete/' + id)
          .then(response => {
            if (response.data.error_code == 1) {
              toast(response?.data?.message || "Đã có lỗi xảy ra!", {
                "type": "error",
                "transition": "flip",
                "dangerouslyHTMLString": true
              })
            } else {
              this.messageNotice = response.data.message;
              this.showNotice = true;
            }
            setTimeout(() => {
                this.showNotice = false;
              }, 2000);
            this.fetchData();
          })
          .catch(error => {
            toast(error?.response?.data?.message || "Đã có lỗi xảy ra!", {
              "type": "error",
              "transition": "flip",
              "dangerouslyHTMLString": true
            })
            console.error('Đã có lỗi xảy ra!', error);
          });
      }

    },
    toggleModal(value, id) {
      this.form = value;
      this.itemId = id;
      this.showModal = !this.showModal;
      if (this.showModal) {
        document.getElementById("main-content").classList.add("overlay-1");
      }
    },

    updateActiveForm(value) {
      this.showModal = value;
      if (!this.showModal) {
        document.getElementById("main-content").classList.remove("overlay-1");
      }
    },

    async fetchData(page = 1) {
      this.isLoading = true;
      if (page) {
        this.page = page
      }
      try{
        let  response  = await apiClient.get('api/cms/admin/list', {
          params: {
            page: this.page,
            searchValue: this.searchValue
          }
        });
        if (response) {
          this.dataTable = response.data.data;
          this.dataUser = response.data;
        }
      } catch (e) {
        console.log(e);
        toast(e?.response?.data?.message || "Đã có lỗi xảy ra!", {
          "type": "error",
          "transition": "flip",
          "dangerouslyHTMLString": true
        })
      }
      this.isLoading = false;
    },
    handleSearch(){
      this.fetchData()
    }
  },
};
</script>
