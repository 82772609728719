<template>
  <div>
    <Notify :showNotice = "showNotice" :messErr = "messErr" :messageNotice="messageNotice"></Notify>
    <div class="flex flex-wrap mt-4 table-main">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
           :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div class="flex flex-wrap items-center" style="justify-content: space-between;">
            <div class="relative px-4 max-w-full">
              <h3 class="font-semibold text-lg" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
                Config Dịch Vụ
              </h3>
            </div>
          </div>
        </div>
        <div class="block w-full overflow-x-auto">
          <div class="flex flex-wrap p-5 mb-4">
            <div class="mr-3 width16">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="merchant_name">
                Tên merchant
              </label>
              <multiselect
                  v-model="merchant_auth"
                  :options="merchantOptions"
                  placeholder="Vui lòng nhập..."
                  :searchable="true"
                  :loading="merchantLoading"
                  :options-limit="50"
                  open-direction="bottom"
                  track-by="name"
                  label="name"
                  :multiple="true"
                  @search-change="searchMerchant"
              ></multiselect>
            </div>
            <div class="mr-3 width16">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="merchant_name">
                Loại dịch vụ
              </label>
              <select class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" v-model="formData.type">
                <option value="">Tất cả</option>
                <option v-for="type in serviceType" :key="type.value" :value="type.value">{{ type.label }}</option>
              </select>
            </div>
            <div class="mr-3 width16">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="merchant_name">
                Trạng thái hoạt động
              </label>
              <select class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" v-model="formData.status">
                <option selected value="">Tất cả</option>
                <option value="1">Đang hoạt động</option>
                <option value="0">Dừng hoạt động</option>
              </select>
            </div>
            <div class="mr-3 width16">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="merchant_name">
                Từ ngày
              </label>
              <input v-model="formData.start_date" type="date" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" id="merchant_name" placeholder="Tất cả">
            </div>
            <div class="width16">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="merchant_name">
                Đến ngày
              </label>
              <input v-model="formData.end_date" type="date" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" id="merchant_name" placeholder="Tất cả">
            </div>
          </div>
          <div class="flex justify-end m-4">
              <button type="button" v-on:keyup.enter="fetchData()" @click="fetchData()" class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"><i class="fa fa-search"></i> Search</button>
              <router-link to="/admin/tool/config-vas/create" class="bg-emerald-500 text-white active:600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"><i class="fa fa-plus-circle"></i> Thêm mới</router-link>
          </div>

          <div class="px-3">
            <!-- Projects table -->
            <table class="table-auto w-full border-collapse">
              <thead>
              <tr>
                <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  STT
                </th>
                <th
                    class="border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Merchant
                </th>
                <th
                    class="border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Nhóm dịch vụ
                </th>
                <th
                    class="border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Trạng thái
                </th>

                <th
                    style="text-align: left"
                    class="border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Thời gian hiệu lực
                </th>
                <th
                    style="text-align: left"
                    class="border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-right"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Người thao tác
                </th>
                <th
                    class="border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right"
                    :class="[
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                  ]">
                  Hành động
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in dataTable" :key="index">
                <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span class="ml-3 font-bold" :class="[
                      color === 'light' ? 'text-blueGray-600' : 'text-white',
                    ]">
                      {{ index + 1 }}
                    </span>
                </td>
                <td
                    class="border-t-0 border-l-0 border-r-0 text-xs whitespace-nowrap">
                    <span class="font-bold" :class="[
                      color === 'light' ? 'text-blueGray-600' : 'text-white',
                    ]">
                      {{ item.merchant_name ?? ''}}
                    </span>
                </td>
                <td class="border-t-0 border-l-0 border-r-0 text-xs whitespace-nowrap">
                    {{ item.group_name }}
                </td>
                <td class="border-t-0 border-l-0 border-r-0 text-xs whitespace-nowrap">
                  <span v-if="item.status == 1" class="badge badge-success">Đang hoạt động</span>
                  <span v-if="item.status == 0" class="badge badge-error">Dừng hoạt động</span>
                </td>
                <td
                    class="border-t-0 border-l-0 border-r-0 text-xs whitespace-nowrap text-left">
                    <span class="font-bold" :class="[
                      color === 'light' ? 'text-blueGray-600' : 'text-white',
                    ]">
                      {{item.start_date}} - {{item.end_date}}
                    </span>
                </td>
                <td
                    class="border-t-0 border-l-0 border-r-0 text-xs whitespace-nowrap text-left">
                    <span class="font-bold" :class="[
                      color === 'light' ? 'text-blueGray-600' : 'text-white',
                    ]">
                      {{item.created_by ?? ''}}
                    </span>
                </td>
                <td
                    class="border-t-0 border-l-0 border-r-0 text-xs whitespace-nowrap text-right">
                    <span class="font-bold" :class="[
                      color === 'light' ? 'text-blueGray-600' : 'text-white',
                    ]">
                      <router-link :to="'/admin/tool/config-vas/' + item.id" style="color: #039be5" class="mr-3"><i style="color: #28A745" class="fa fa-eye fa-2x"></i></router-link>
                      <router-link :to="'/admin/tool/config-vas/edit/' + item.id" style="color: #039be5"><i style="color: #0CADFC" class="fa fa-edit fa-2x"></i></router-link>
                    </span>
                </td>
              </tr>
              </tbody>
              <tfoot style="height: 50px;">
              <Pagination :dataPage="dataUser" @pageChange:index="fetchData" />
              </tfoot>
            </table>
            <div v-if="isLoading" class="loading absolute loading_table" >
              <img :src="loadingImage" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.table-main table tbody {
  font-size: 22px;
  font-weight: 500;
}
.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-error {
  color: #fff;
  background-color: #dc3545;
}

</style>
<script>
import Pagination from "@/components/Panigation.vue";
import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";
import apiClient from '@/services/api.js';
import Notify from "@/components/Notification/Notify.vue"
import moment from 'moment';
import loadingImage from "@/assets/img/loading.gif";
import {toast} from "vue3-toastify";
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.css';
import {debounce} from "vue-debounce";

const debounceFn = debounce(callback => callback(), '400ms')

export default {
  data() {
    return {
      bootstrap,
      angular,
      sketch,
      react,
      vue,
      showModal: false,
      dataTable: [],
      dataUser: [],
      formData: {
        merchant_ids: '',
        status: '',
        type: '',
        start_date: moment().subtract(30, "days").format('YYYY-MM-DD'),
        end_date: moment().add(30, 'days').format('YYYY-MM-DD'),
      },
      limit: 10,
      page: null,
      messageNotice: '',
      messErr: false,
      showNotice: false,
      serviceType: [
        {value: 'TOPUP_MOBILE', label: 'Nạp thẻ điện thoại'},
        {value: 'GAME_CARD', label: 'Thẻ game'},
        {value: 'BILLING', label: 'Thanh toán hoá đơn'},
      ],
      isLoading: false,
      loadingImage,
      merchantOptions: [],
      merchantLoading: false,
      merchant_auth: [],
    };
  },
  components: {
    Multiselect,
    Pagination,
    Notify,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  created() {
    this.fetchData();
    this.searchMerchant();
    window.addEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(e) {
      if (e.keyCode === 13) {
        this.fetchData();
      }
    },
    fetchData(value = 1) {
      this.formData.page = value;
      this.isLoading = true;

      if (!this.checkDate()) {
        return false;
      }

      this.formData.merchant_ids = this.merchant_auth.map(item => item.id)

      apiClient.get('api/config-vas/list', {params: this.formData})
        .then(response => {
          if (response.data.error_code !== 0) {
            toast(response?.data?.message || "Đã có lỗi xảy ra!", {
              "type": "error",
              "transition": "flip",
              "dangerouslyHTMLString": true
            })
            return
          }

          this.dataTable = response.data.data.data;
          this.dataUser = response.data.data;
          this.isLoading = false;
        })
        .catch(error => {
          toast(error?.response?.data?.message || "Đã có lỗi xảy ra!", {
            "type": "error",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
          console.error('Đã có lỗi xảy ra!', error);
          this.isLoading = false;
        });
    },
    checkDate() {
      this.isLoading = false;
      this.showNotice = false;

      if (!this.formData.start_date || !this.formData.end_date) {
        this.showNotice = true;
        this.messErr = 'Phải nhập đầy đủ thời gian tìm kiếm'
        return false;
      }

      let dateFrom = moment(String(this.formData.start_date));
      let dateTo = moment(String(this.formData.end_date));

      if (!dateFrom.isBefore(dateTo) && !dateFrom.isSame(dateTo)) {
        this.showNotice = true;
        this.messErr = 'Từ thời gian không được lớn hơn Đến thời gian'
        return false;
      }

      return true;
    },
    searchMerchant(query) {
      debounceFn(async () => {
        this.merchantLoading = true;
        const self = this;
        try {
          let { data } = await apiClient.get('api/merchant/search', {
            params: {keyword: query}
          })
          self.merchantLoading = false;
          if (data.status) {
            self.merchantOptions = data.data;
          }
        } catch (e) {
          console.log(e);
          toast(e?.response?.data?.message || "Đã có lỗi xảy ra!", {
            "type": "error",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
        }
      })
    },
  },
};
</script>
