export default class WebStorage {
    constructor (storageType, jsonSerialize = true) {
        switch (storageType) {
            case 'session':
            default:
                this.storageEngine = sessionStorage
                break
            case 'local':
                this.storageEngine = localStorage
                break
        }

        this.JsonSerialize = jsonSerialize
    }

    get (key) {
        const storedData = this.storageEngine.getItem(key)
        if (storedData) {
            return this.JsonSerialize ? JSON.parse(storedData) : storedData
        } else {
            return null
        }
    }

    set (key, data) {
        try {
            if (this.JsonSerialize) {
                data = JSON.stringify(data)
            }
            this.storageEngine.setItem(key, data)
        } catch (e) {
            console.log(e)
        }
    }

    remove (key) {
        this.storageEngine.removeItem(key)
    }

    clear () {
        this.storageEngine.clear()
    }

    slice (key) {
        const data = this.get(key)
        this.remove(key)

        return data
    }
}