<template>
  <div>
    <div class="flex flex-wrap mt-4 table-main">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-5">
        <div class="rounded-t mb-4 px-4 py-3 border-0">
          <div class="flex flex-wrap items-center" style="justify-content: space-between;">
            <div class="relative px-4 max-w-full">
              <h3 class="font-semibold text-lg text-blueGray-700">
                Chi tiết config chi phí
              </h3>
            </div>
          </div>
        </div>
        <div class="bg-white block w-full overflow-x-autoflex-auto px-4 lg:px-10 py-10 pt-0">
          <alert v-if="alertMessage" :message="alertMessage" :type="alertType"></alert>
          <form-config-fee v-if="config" @submit-form="handleSaveConfig" :config_data="config"></form-config-fee>
        </div>
        <div class="flex flex-wrap mt-4 table-main action_button_form_fee">
          <button type="submit" form="form_config_fee"
                  class="py-2 px-8 bg-emerald-500 hover:bg-emerald-600 text-white font-semibold rounded-lg shadow-md focus:outline-none">
            Duyệt
          </button>
          <button
              v-if="status_config !== 2"
              @click="rejectFee()"
              class="py-2 px-6 bg-red-500 hover:bg-red-500 text-white font-semibold rounded-lg shadow-md focus:outline-none">
            Từ chối
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.table-main table tbody {
  font-size: 22px;
  font-weight: 500;
}
</style>

<script>

import FormConfigFee from "@/components/Fee/FormConfigFee.vue";
import apiClient from '@/services/api.js';
import Alert from "@/components/Alerts/Alert.vue"
import {toast} from "vue3-toastify";

export default {
  data() {
    return {
      alertMessage: '',
      alertType: 'info',
      config: null,
      status_config: null,
    };
  },
  components: {
    FormConfigFee,
    Alert,
  },
  props: {
    //
  },
  async created() {
    let { data } = await apiClient.get('api/fee/' + this.$route.params.id);
    if (data.status) {
      this.config = data.data;
      this.status_config = data.data.status_config
    }
  },
  watch: {

  },
  methods: {
    async handleSaveConfig(configData) {
      try {
        this.alertMessage = '';
        let response = await apiClient.put('api/fee/' + this.$route.params.id, {
          ...configData
        });
        if (response.data.status) {
          this.alertMessage = response.data.message;
          this.alertType = 'success';
          window.scroll({
            top: 10,
            left: 10,
            behavior: "smooth",
          });
          let self = this;
          setTimeout(function () {
            self.$router.push({ name: 'admin-config-fee' });
          }, 500);
        } else {
          this.alertMessage = response.data.message || 'Cập nhật thất bại. Vui lòng thử lại sau!';
          this.alertType = 'error';
          window.scroll({
            top: 10,
            left: 10,
            behavior: "smooth",
          });
        }
      } catch (e) {
        console.log(e)
      }
    },
    async rejectFee() {
      let conf = confirm("Bạn có chắc chắn từ chối yêu cầu này không?");

      if (conf === false) {
        return true;
      }
      try {
        this.isLoading = true;
        let data = await apiClient.post(`api/fee/reject/`, {id: this.$route.params.id});
        if (data.data.error_type === 'api' ) {
          toast("Bạn không có quyền duyệt!", {
            "type": "warning",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
          this.isLoading = false;
        } else {
          toast("Yêu cầu từ chối thành công", {
            "type": "success",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
          let self = this;
          setTimeout(function () {
            self.$router.push({ name: 'admin-config-fee' });
          }, 500);
        }

      } catch (e) {
        toast("Có lỗi xả ra trong quá trình", {
          "type": "error",
          "transition": "flip",
          "dangerouslyHTMLString": true
        })
      }
    },
    reload() {
      location.reload()
    }
  },
};
</script>
