<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div  v-if="showNotice && messErr" class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500"
              style="z-index: 9999;">
              <span class="inline-block align-middle mr-8">
                <b class="capitalize"></b>{{ messageNotice }}
              </span>
            </div>
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="m-login__logo">
              <a href="#">
                <img src="https://stg-cms.wallet.9pay.mobi/images/logo1.svg">
              </a>
            </div>
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                Sign in with
              </h6>
            </div>
            <div class="btn-wrapper text-center">
              <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button" @click="handleClickSignIn()">
                <img alt="..." class="w-5 mr-1" :src="google" />
                Google
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, toRefs } from "vue";
import google from "@/assets/img/google.svg";
import WebStorage from '@/services/webStorage'
const webLocalStorage = new WebStorage('local');
import apiClient from "@/services/api-v2";

export default {
  name: "Login",
  props: {
    msg: String,
  },

  data() {
    return {
      user: '',
      google,
      params: [],
      showNotice: false,
      messageNotice: '',
      messErr: false,
    }
  },

  methods: {
    async handleClickSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        let ggResponse = this.$gAuth.instance.currentUser.get().getAuthResponse();
        apiClient.post('/auth/login', { 'google_token_oauth': ggResponse.access_token })
            .then(response => {
              if (response) {
                if (response.data.error_code === 0) {
                  webLocalStorage.set('sdk_token', {
                    access_token: response.data.data.access_token,
                    refresh_token: response.data.data.refresh_token
                  })

                  webLocalStorage.set('user_info', JSON.stringify(response.data.data.user));
                  this.$store.mutations.login();
                  this.$router.push({name: "dashboard"});
                  return true;
                }

                this.messageNotice = 'Tài khoản đã bị khóa hoặc không tồn tại. Vui lòng liên hệ quản trị viên!';
                this.messErr = true;
                this.showNotice = true;
                setTimeout(() => {
                  this.hideMessage();
                }, 5000);
                return false;
              } else {
                this.$router.push({ path: "/auth/login" });
                this.$store.mutations.logout();
              }
            });

      } catch (error) {
        alert('Đăng nhập thất bại. Vui lòng thử lại sau')
        //on fail do something
        console.error(error);
        return null;
      }
    },

    async handleClickSignOut() {
      try {
        await this.$gAuth.signOut();
        this.user = "";
      } catch (error) {
        console.error(error);
      }
    },

  hideMessage() {
    this.messageNotice = '';
    this.messErr = false;
    this.showNotice = false;
  },
  },
  setup(props) {
    const { isSignIn } = toRefs(props);
    const Vue3GoogleOauth = inject("Vue3GoogleOauth");

    const handleClickLogin = () => { };
    return {
      Vue3GoogleOauth,
      handleClickLogin,
      isSignIn,
    };
  },
  created() {
    webLocalStorage.remove('sdk_token')
    webLocalStorage.remove('user_info')
    webLocalStorage.remove('sdk_refresh_token')
    this.$store.mutations.logout();
  }

};
</script>
