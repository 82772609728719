// store.js
import { reactive, toRefs } from 'vue';

export const auth = () => {
  return JSON.parse(localStorage.getItem('user_info') || "{}");
}
console.log('auth store', auth());
const state = reactive({
  isAuthenticated: false,
  auth: auth()
});

const mutations = {
  login() {
    state.isAuthenticated = true;
    state.auth = auth();
  },
  logout() {
    state.isAuthenticated = false;
    state.auth = null;
  },
};

export default {
  ...toRefs(state),
  mutations,
};
