<template>
  <div style="width: 100%">
    <div class="flex flex-wrap mt-4 table-main">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-5">
        <div class="rounded-t mb-4 px-4 py-3 border-0">
          <div class="flex flex-wrap items-center" style="justify-content: space-between;">
            <div class="relative px-4 max-w-full">
              <h3 class="font-semibold text-lg text-blueGray-700">
                Báo cáo phí theo giao dịch
              </h3>
            </div>
          </div>
        </div>
        <div class="bg-white block w-full overflow-x-autoflex-auto px-4 lg:px-10 py-10 pt-0" style="padding-left: 1.5rem;padding-right: 1.5rem">
          <alert v-if="alertMessage" :message="alertMessage" :type="alertType"></alert>
          <form >
            <div class="w-full flex flex-wrap mb-3 mt-3 items-center" style="grid-row-gap: 10px;">
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Đối tượng trả Phí
                  </label>
                  <select v-model="object_type" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <option value="" selected>Chon đối tượng</option>
                    <option value="1">Merchant</option>
                    <option value="2">User</option>
                  </select>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Loại chi phí
                  </label>
                  <select v-model="type" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <option value="" selected>Tất cả</option>
                    <option value="1">Phí thu</option>
                  </select>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Tên Merchant
                  </label>
                  <multiselect
                      v-model="merchant_selected"
                      :options="merchantOptions"
                      placeholder="Vui lòng nhập..."
                      :searchable="true"
                      :loading="merchantLoading"
                      :options-limit="50"
                      open-direction="bottom"
                      track-by="name"
                      label="name"
                      @search-change="searchMerchant"
                  ></multiselect>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Loại dịch vụ
                  </label>
                  <multiselect
                      v-model="service_type_selected"
                      tag-placeholder="Vui lòng lựa chọn"
                      placeholder="Vui lòng chọn ..."
                      label="label"
                      track-by="id"
                      :options="service_types"
                      :multiple="true"
                      :taggable="true"
                  ></multiselect>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Mã giao dịch
                  </label>
                  <input v-model="transaction_code" placeholder="Nhập mã giao dịch..." class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Tìm kiếm theo
                  </label>
                  <select v-model="date_type" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <option value="1" selected>Thời gian tạo GD</option>
                    <option value="2">Thời gian hoàn thành</option>
                  </select>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Từ ngày
                  </label>
                  <input
                      type="date"
                      lang="vi"
                      v-model="date_from"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Đến ngày
                  </label>
                  <input
                      type="date"
                      lang="vi"
                      v-model="date_to"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>
            <div class="max-w-sm w-full lg:max-w-full lg:flex mt-3 justify-end">
              <button @click="handleSearch" class="hover:bg-emerald-600 bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-3 rounded shadow outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" style="margin-right: 0.9rem;">
                <i class="fas fa-search"></i> Tìm kiếm </button>
            </div>
          </form>
        </div>
        <div class="bg-white block w-full overflow-x-autoflex-auto px-4 lg:px-10 pt-0 flex justify-between items-center" style="grid-column-gap: 40px;">
          <div class="max-w-sm mt-3">
            <p class="text-sm">Tổng số GD: <b>{{ overview.countTransaction }}</b> giao dịch</p>
            <p class="text-sm">Tổng tiền GD: <b>{{ overview.totalAmount }}</b> VNĐ</p>
            <p class="text-sm">Phí xử lý: <b>{{ overview.totalProcessFee }}</b></p>
            <p class="text-sm">Phí GD: <b>{{ overview.totalTransactionFee }}</b></p>
          </div>
          <div class="max-w-sm mt-3">
            <button @click="handleDownloadFile" class="hover:bg-lightBlue-600 bg-lightBlue-500 text-white font-bold uppercase text-xs px-4 py-3 rounded shadow outline-none focus:outline-none ease-linear transition-all duration-150 relative" type="button">
              <div v-if="isExporting" style="height: 100%; width: 100%; cursor: wait">
                <i style="position: relative; z-index: 1;">Đang xuất {{ progressExport }}%</i>
                <div class="progress-export" :style="{ width: `${progressExport}%` }"></div>
              </div>
              <div v-else>
                <i class="fas fa-download"></i> Xuất file
              </div>
            </button>
          </div>
        </div>
        <div class="relative px-4 lg:px-10 py-10">
          <!-- Projects table -->
          <div class="overflow-x-table block overflow-x-auto relative">
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
              <tr>
                <th
                    class="text-center width10 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  STT
                </th>
                <th
                    class="text-center width10 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Mã giao dịch
                </th>
                <th
                    class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width10 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Đối tượng trả phí
                </th>
                <th
                    class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Tên Merchant
                </th>
                <th
                    class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right"
                >
                  Giá gốc
                </th>
                <th
                    class="text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right"
                >
                  Giá trị GD
                </th>
                <th
                    class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Loại dịch vụ
                </th>
                <th
                    class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  SĐT
                </th>
                <th
                    class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Mô tả GD
                </th>
                <th
                    class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Trạng thái Ví
                </th>
                <th
                    class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Trạng thái HĐ
                </th>
                <th
                    class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  TG tạo
                </th>
                <th
                    class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  TG thanh toán
                </th>
                <th
                    class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right"
                >
                  Phí xử lý
                </th>
                <th
                    class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right"
                >
                  Phí GD
                </th>
              </tr>
              </thead>
              <tbody>
              <tr :style="{backgroundColor: index % 2 === 0 ? '#ecf0f5' : ''}" v-bind:key="index" v-for="(transaction, index) in data_transaction">
                <td class="text-center border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ index + 1 }}</td>
                <td class="text-center border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.code }}</td>
                <td class="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.object_fee?.label }}</td>
                <td class="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.merchant?.name || "-" }}</td>
                <td class="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">{{ transaction.actual_amount }}</td>
                <td class="text-center border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">{{ transaction.amount }}</td>
                <td class="wrap_service_type_labels border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <label class="cf_service_type_label">{{ transaction.type.label }}</label>
                </td>
                <td class="text-center border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.user_phone }}</td>
                <td class="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs p-4" style="max-width: 100px;">{{ transaction.title }}</td>
                <td class="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.request_status.label }}</td>
                <td class="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.status.label }}</td>
                <td class="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {{ transaction.created_hour }}
                  <br/>
                  {{ transaction.created_date }}
                </td>
                <td class="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {{ transaction.completed_hour }}
                  <br/>
                  {{ transaction.completed_date }}
                </td>
                <td class="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">{{ transaction.processing_cost }}</td>
                <td class="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">{{ transaction.fee }}</td>
              </tr>
              </tbody>
              <tfoot style="height: 10px;">
              </tfoot>
            </table>
            <div v-if="data_transaction.length === 0" class="items-center w-full bg-transparent border-collapse text-center" style="padding: 20px 0;">
              <i>Không có dữ liệu!</i>
            </div>
          </div>
          <div style="height: 50px">
            <div v-if="isLoading" class="loading absolute loading_table" >
              <img :src="loadingImage" alt="">
            </div>
            <Panigation :dataPage="data_transaction_paginate" @pageChange:index="fetchData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.table-main table tbody {
  font-size: 22px;
  font-weight: 500;
}
.cf_service_type_label {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #10b981;
  color: #10b981;
}
.wrap_service_type_labels {
  display: flex;
  grid-column-gap: 8px;
  flex-wrap: wrap;
  grid-row-gap: 7px;
}
.progress-export {
  height: 100%;
  position: absolute;
  background-color: #01dd2e;
  opacity: 0.5;
  top: 0;
  left: 0;
  transition: 2s;
}
</style>

<script>

import apiClient from '@/services/api.js';
import Alert from "@/components/Alerts/Alert.vue"
import ServiceTypes from "@/config/fees/service-type";
import Multiselect from "vue-multiselect";
import {debounce} from 'vue-debounce'
import Panigation from "@/components/Panigation.vue";
import loadingImage from "@/assets/img/loading.gif";
import {FormatCurrency} from '@/utils/numberUtils';
import { toast } from 'vue3-toastify';
import moment from "moment";

const debounceFn = debounce(callback => callback(), '400ms')

export default {
  data() {
    return {
      overview: {
        countTransaction: 0,
        totalAmount: 0,
        totalProcessFee: 0,
        totalTransactionFee: 0
      },
      alertMessage: '',
      alertType: 'info',
      type: '',
      object_type: '',
      service_types: ServiceTypes,
      service_type_selected: [],
      date_type: 2,
      date_from: moment().add(-7, 'days').format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      merchant_selected: null,
      merchantOptions: [],
      merchantLoading: false,
      data_transaction: [],
      data_transaction_paginate: [],
      transaction_code: '',
      page: null,
      loadingImage,
      isLoading: false,
      totalServiceType: ServiceTypes.length,
      progressExport: 0,
      isExporting: false,
    };
  },
  components: {
    Panigation,
    Multiselect,
    Alert,
  },
  props: {
    //
  },
  created() {
    this.searchMerchant();
    this.fetchData();
  },
  mounted() {
    //
  },
  watch: {
    //
  },
  methods: {
    searchMerchant(query) {
      debounceFn(async () => {
        this.merchantLoading = true;
        const self = this;
        try {
          let { data } = await apiClient.get('api/merchant/search', {
            params: {keyword: query}
          })
          self.merchantLoading = false;
          if (data.status) {
            self.merchantOptions = data.data;
          }
        } catch (e) {
          console.log(e);
          toast(e?.response?.data?.message || "Đã có lỗi xảy ra!", {
            "type": "error",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
        }
      })
    },
    async fetchData(page = 1) {
      this.isLoading = true;
      if (page) {
        this.page = page
      }
      try {
        const params = {
          page: this.page,
          object_type: this.object_type,
          type: this.type,
          transaction_code: this.transaction_code,
          merchant_key: this.merchant_selected?.merchant_key,
          service_types: this.service_type_selected.map(item => item.id),
          date_type: this.date_type,
          date_from: this.date_from,
          date_to: this.date_to,
        }

        const [{data: reponseTransactionData}, {data: reponseTransactionOverviewData}] = await Promise.all([
          apiClient.get('api/cms/reconcile/transaction', {params}),
          apiClient.get('api/cms/reconcile/transaction/overview', {params}),
        ]);
        if (reponseTransactionData.status) {
          this.data_transaction = reponseTransactionData.data.data.map((item) => {
            item.amount = FormatCurrency(item.amount);
            item.actual_amount = FormatCurrency(item.actual_amount);
            if (!item.transaction_fee) {
              item.transaction_fee = {};
            }
            item.processing_cost = FormatCurrency(item.processing_cost || 0);
            item.fee = FormatCurrency(item.fee);

            return item;
          });
          this.data_transaction_paginate = reponseTransactionData.data;
        }
        if (reponseTransactionOverviewData.status) {
          this.overview.countTransaction = FormatCurrency(reponseTransactionOverviewData.data.count_transaction);
          this.overview.totalAmount = FormatCurrency(reponseTransactionOverviewData.data.total_amount);
          this.overview.totalTransactionFee = FormatCurrency(reponseTransactionOverviewData.data.total_transactions_fee);
          this.overview.totalProcessFee = FormatCurrency(reponseTransactionOverviewData.data.total_processing_fee);
        }
      } catch (e) {
        console.log(e);
        toast(e?.response?.data?.message || "Đã có lỗi xảy ra!", {
          "type": "error",
          "transition": "flip",
          "dangerouslyHTMLString": true
        })
      }
      this.isLoading = false;
    },
    handleSearch() {
      this.fetchData();
    },
    async handleDownloadFile() {
      if (this.isExporting) {
        return;
      }
      toast("Yêu cầu đang được xử lý! Vui lòng không rời khỏi màn hình", {
        "type": "warning",
        "transition": "flip",
        "dangerouslyHTMLString": true
      });

      this.isExporting = true;
      this.progressExport = 0;
      const params = {
        page: this.page,
        object_type: this.object_type,
        type: this.type,
        transaction_code: this.transaction_code,
        merchant_key: this.merchant_selected?.merchant_key,
        service_types: this.service_type_selected.map(item => item.id),
        date_type: this.date_type,
        date_from: this.date_from,
        date_to: this.date_to,
      }

      try {
        let reponsePushAction = await apiClient.get('api/cms/reconcile/transaction/export', {params});
        if (reponsePushAction.data.status) {
          const SELF = this;
          let interval = setInterval(function () {
            apiClient.get('api/cms/reconcile/transaction/export', {params: {...params, key: reponsePushAction.data.data.key}})
                .then(data => data.data.data)
                .then(data => {
                  if (data.progress === 100) {
                    clearInterval(interval);
                    SELF.progressExport = 100;
                    toast("Xuất file thành công!", {
                      "type": "success",
                      "transition": "flip",
                      "dangerouslyHTMLString": true
                    });
                    setTimeout(function () {
                      SELF.isExporting = false;
                    }, 3000);

                    let labelFileName = '';
                    if (SELF.date_from && SELF.date_to) {
                      labelFileName = ` ${moment(SELF.date_from).format('DD-MM-YYYY')} _ ${moment(SELF.date_to).format('DD-MM-YYYY')}`;
                    }

                    window.location.href = (`${process.env.VUE_APP_BACKEND_URL}/storage/exports/${data.file_name}?file_name=Báo cáo phí thu${labelFileName}.csv`);
                  }
                  SELF.progressExport = data.progress;
                }).catch((e) => {
                  console.log(e)
                  clearInterval(interval);
                  SELF.isExporting = false;
                  toast(e?.response?.data?.message || "Xuất file thất bại!", {
                    "type": "error",
                    "transition": "flip",
                    "dangerouslyHTMLString": true
                  })
            });
          }, 3000);
        }
      } catch (e) {
        toast(e?.response?.data?.message || "Xuất file thất bại!", {
          "type": "error",
          "transition": "flip",
          "dangerouslyHTMLString": true
        })
        console.log(e);
      }
    }
  },
};
</script>
