<template>
  <div>
    <div
        class="dropzone-container"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
        :style="isDragging && 'border-color: #f9934c;'"
    >
      <input
          type="file"
          name="file"
          id="fileInput"
          class="hidden-input"
          @change="onChange"
          ref="file"
          accept=".csv,.xls,.xlsx"
      />

      <label for="fileInput" class="file-label" style="height: 100%;">
        <div v-if="isDragging" class="box-drop-file">Nhả để thả tập tin vào đây</div>
        <div v-else class="text-center box-drop-file">
          <p><strong>Kéo, thả file</strong></p>
          <p>File tải lên với định dạng: xlsx và không quá 200MB</p>
          <p style="margin-top: 15px;">
            <a href="/template/merchant-qr/file_mau.xlsx" download style="color: #10b981">Tải file mẫu</a>
          </p>
        </div>
      </label>
      <div class="preview-container mt-4" v-if="!isDragging && files.length">
        <div class="preview-cart" v-for="file in files" :key="file.name">
          <p style="width: 200px">{{ file.name }}</p>
          <button
              class="ml-3"
              type="button"
              @click="remove(file)"
              title="Remove file"
          >
            <strong class="remove-file">×</strong>
          </button>
        </div>
      </div>
      <p class="mt-3" style="color: #df6511"><i>Trường hợp Upload file dữ liệu và nhập dữ liệu thủ công đồng thời, hệ thống sẽ chỉ thực hiện thêm mới dữ liệu từ file excel</i></p>
    </div>
  </div>
</template>
<style>
.dropzone-container {
  height: 250px;
  background: #f7fafc;
  border: 2px dashed #9e9e9e;
  position: relative;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  display: block;
  cursor: pointer;
}

.preview-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2rem;
  position: absolute;
  bottom: 30px;
  left: 41%;
}
.preview-cart {
  display: flex;
  background-color: orange;
  padding: 3px 15px;
  border-radius: 10px;
  color: #fff;
}
.preview-cart .remove-file {
  background: red;
  padding: 0 4px 1px;
  border-radius: 50%;
}
.box-drop-file {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
input[type='radio']:disabled {
  background-color: #9e9e9e;
}
</style>
<script>
export default {
  data() {
    return {
      isDragging: false,
      files: [],
    };
  },
  methods: {
    onChange() {
      let FILE = this.$refs?.file?.files;
      if (FILE?.length > 1) {
        this.files = [FILE[0]];
        return;
      }

      this.files = FILE || [];
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove() {
      this.files = [];
      document.querySelector('#fileInput').value = '';
    },
  },
  watch: {
    files(newValue) {
      this.$emit('change:file', newValue?.length > 0 ? newValue[0] : null);
    }
  }
};
</script>
