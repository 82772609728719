<template>
  <div>
    <div class="flex flex-wrap mt-4 table-main">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-5">
        <div class="rounded-t mb-4 py-3 border-0">
          <div class="flex flex-wrap items-center" style="justify-content: space-between;">
            <div class="relative px-4 max-w-full">
              <h3 class="font-semibold text-lg text-blueGray-700">
                Danh sách giao dịch GENQR
              </h3>
            </div>
          </div>
        </div>
        <div class="bg-white block w-full overflow-x-autoflex-auto py-10 pt-0">
          <alert v-if="alertMessage" :message="alertMessage" :type="alertType"></alert>
          <form >
            <div class="max-w-sm w-full lg:max-w-full lg:flex mb-3 mt-3 items-center" style="grid-row-gap: 10px;">
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Tên merchant
                  </label>
                  <multiselect
                      v-model="merchantSelected"
                      :options="merchantOptions"
                      placeholder="Vui lòng nhập..."
                      :searchable="true"
                      :loading="merchantLoading"
                      :options-limit="50"
                      open-direction="bottom"
                      track-by="name"
                      label="name"
                      :multiple="true"
                      @search-change="searchMerchant"
                  ></multiselect>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Master merchant
                  </label>
                  <multiselect
                      v-model="masterMerchantSelected"
                      :options="masterMerchantOptions"
                      placeholder="Vui lòng nhập..."
                      :searchable="true"
                      :loading="masterMerchantLoading"
                      :options-limit="50"
                      open-direction="bottom"
                      track-by="name"
                      label="name"
                      :multiple="true"
                      @search-change="searchMasterMerchant"
                  ></multiselect>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Mã đơn hàng
                  </label>
                  <input v-model="identify_code" placeholder="Nhập mã đơn hàng" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Mã giao dịch
                  </label>
                  <input v-model="transaction_code" placeholder="Nhập giao dịch" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Phương thức thanh toán
                  </label>
                  <select v-model="payment_method" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <option value="" selected>Tất cả</option>
                    <option value="1">Số dư Ví 9Pay</option>
                    <option value="2">Phương thức khác</option>
                  </select>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Trạng thái 9S
                  </label>
                  <select v-model="status_nine_s" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <option value="" selected>Tất cả</option>
                    <option value="1">Khởi tạo</option>
                    <option value="2">Chờ xử lý</option>
                    <option value="3">Huỷ</option>
                    <option value="4">Thành công</option>
                    <option value="5">Hoàn trả</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="max-w-sm w-full lg:max-w-full lg:flex mb-3 mt-3 items-center" style="grid-row-gap: 10px;">
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Trạng thái đơn hàng
                  </label>
                  <select v-model="status" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <option value="" selected>Tất cả</option>
                    <option value="1">Khởi tạo</option>
                    <option value="2">Đang xử lý</option>
                    <option value="3">Thành công</option>
                    <option value="4">Thất bại</option>
                    <option value="5">Hủy</option>
                  </select>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Tìm kiếm theo ngày
                  </label>
                  <select v-model="date_type" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <option value="1">Ngày tạo</option>
                    <option value="2">Ngày hoàn thành</option>
                  </select>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Từ ngày
                  </label>
                  <input
                      type="date"
                      lang="vi"
                      v-model="date_from"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Đến ngày
                  </label>
                  <input
                      type="date"
                      lang="vi"
                      v-model="date_to"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Giá trị từ
                  </label>
                  <input v-model="amount_from" type="number" min="0" step="1000" placeholder="Nhập giá trị từ" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Giá trị đến
                  </label>
                  <input v-model="amount_to" type="number" min="0" step="1000" placeholder="Nhập giá trị đến" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                </div>
              </div>
            </div>
            <div class="bg-white block w-full overflow-x-autoflex-auto px-4 lg:px-10 pt-0 flex justify-between items-center" style="grid-column-gap: 40px;">
              <div class="max-w-sm mt-3">
                <p class="text-sm">Số chi nhánh: <b>{{ overview.count_merchant || 0 }}</b></p>
                <p class="text-sm">Tổng số GD: <b>{{ overview.count_transaction || 0 }}</b> giao dịch</p>
                <p class="text-sm">Tổng tiền: <b>{{ overview.sum_amount || 0 }}</b> VNĐ</p>
              </div>
              <div class="max-w-sm mt-3">
                <button @click="handleDownloadFile" class="mr-3 hover:bg-lightBlue-600 bg-lightBlue-500 text-white font-bold uppercase text-xs px-4 py-3 rounded shadow outline-none focus:outline-none ease-linear transition-all duration-150 relative" type="button">
                  <div v-if="isExporting" style="height: 100%; width: 100%; cursor: wait">
                    <i style="position: relative; z-index: 1;">Đang xuất {{ progressExport }}%</i>
                    <div class="progress-export" :style="{ width: `${progressExport}%` }"></div>
                  </div>
                  <div v-else>
                    <i class="fas fa-download"></i> Xuất file
                  </div>
                </button>
                <button @click="handleSearch" class="hover:bg-emerald-600 bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-3 rounded shadow outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button">
                  <i class="fas fa-search"></i> Tìm kiếm </button>
              </div>
            </div>
          </form>
        </div>
        <div class="block w-full overflow-x-auto relative" style="width: 100%; height: 100%">
          <!-- Projects table -->
          <table class="items-center w-full bg-transparent border-collapse list-config">
            <thead>
            <tr>
              <th
                  class="text-center width10 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                STT
              </th>
              <th
                  class="width10 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                Tên merchant
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width10 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
               >
                Mã đơn hàng
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Mã giao dịch
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Master Merchant
              </th>
              <th
                  class="text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Giá trị HĐ
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Phương thức TT
              </th>
              <th
                  class="text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                SĐT
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Nội dung
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Trạng thái 9S
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Trạng thái HĐ
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                TG tạo
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                TG thanh toán
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Hành động
              </th>
            </tr>
            </thead>
            <tbody>
              <tr :style="{backgroundColor: index % 2 === 0 ? '#ecf0f5' : ''}" v-bind:key="index" v-for="(transaction, index) in data_transaction">
                <td class="text-center border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ index + 1 }}</td>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.merchant_name }}</td>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.identify_code }}</td>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.transaction_code }}</td>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.master_merchant_name }}</td>
                <td class="text-center border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.amount }}</td>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.payment_method_name }}</td>
                <td class="text-center border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.source_bank_phone }}</td>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.content }}</td>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.nine_s_status }}</td>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.invoice_status }}</td>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.created_at }}</td>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{ transaction.completed_at }}</td>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <router-link :to="'/admin/va-transaction/' + transaction.id" style="color: #039be5">Chi tiết</router-link>
                </td>
              </tr>
            </tbody>
            <tfoot style="height: 50px;">
              <Panigation :dataPage="data_transaction_paginate" @pageChange:index="fetchData" />
            </tfoot>
          </table>
          <div v-if="data_transaction.length === 0" class="items-center w-full bg-transparent border-collapse" style="height: 150px;">
            <i>Không có dữ liệu!</i>
          </div>
          <div v-if="isLoading" class="loading absolute loading_table" >
            <img :src="loadingImage" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.table-main table tbody {
  font-size: 22px;
  font-weight: 500;
}
.cf_service_type_label {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #10b981;
  color: #10b981;
}
.cf_service_type_label_all {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #f97316;
  color: #f97316;
}

.list-config .wrap_service_type_labels {
  display: flex;
  grid-column-gap: 8px;
  flex-wrap: wrap;
  grid-row-gap: 7px;
}

.progress-export {
  height: 100%;
  position: absolute;
  background-color: #01dd2e;
  opacity: 0.5;
  top: 0;
  left: 0;
  transition: 2s;
}
</style>

<script>

import apiClient from '@/services/api.js';
import Alert from "@/components/Alerts/Alert.vue"
import Multiselect from "vue-multiselect";
import {debounce} from 'vue-debounce'
import Panigation from "@/components/Panigation.vue";
import loadingImage from "@/assets/img/loading.gif";
import { toast } from 'vue3-toastify';
import {FormatCurrency} from "@/utils/numberUtils";

const debounceMerchantFn = debounce(callback => callback(), '400ms')
const debounceMasterMerchantFn = debounce(callback => callback(), '400ms')

export default {
  data() {
    return {
      alertMessage: '',
      alertType: 'info',
      data_transaction: [],
      data_transaction_paginate: [],
      merchantOptions: [],
      merchantSelected: [],
      merchantLoading: false,
      masterMerchantOptions: [],
      masterMerchantSelected: [],
      masterMerchantLoading: false,
      transaction_code: '',
      identify_code: '',
      payment_method: '',
      status_nine_s: '',
      status: '',
      date_type: 1,
      date_from: '',
      date_to: '',
      amount_to: '',
      amount_from: '',
      page: null,
      overview: {
        count_transaction: 0,
        count_merchant: 0,
        sum_amount: 0,
      },
      loadingImage,
      isLoading: false,
      progressExport: 0,
      isExporting: false,
    };
  },
  components: {
    Panigation,
    Multiselect,
    Alert,
  },
  props: {
    //
  },
  created() {
    this.searchMerchant();
    this.searchMasterMerchant();
    this.fetchData();
  },
  watch: {
    //
  },
  methods: {
    searchMerchant(query) {
      debounceMerchantFn(async () => {
        this.merchantLoading = true;
        const self = this;
        try {
          let { data } = await apiClient.get(process.env.VUE_APP_PAYMENT_QR_URL + '/api/internal/merchant/advance-search', {
            params: {keyword: query}
          })
          self.merchantLoading = false;
          if (data.success) {
            self.merchantOptions = data.data;
          }
        } catch (e) {
          console.log(e);
          toast("Đã có lỗi xảy ra!", {
            "type": "error",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
        }
        self.merchantLoading = false;
      })
    },
    searchMasterMerchant(query) {
      debounceMasterMerchantFn(async () => {
        this.masterMerchantLoading = true;
        const self = this;
        try {
          let { data } = await apiClient.get(process.env.VUE_APP_PAYMENT_QR_URL + '/api/internal/merchant/advance-search', {
            params: {keyword: query, is_parent: 1}
          })
          self.masterMerchantLoading = false;
          if (data.success) {
            self.masterMerchantOptions = data.data;
          }
        } catch (e) {
          console.log(e);
          toast("Đã có lỗi xảy ra!", {
            "type": "error",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
        }
        self.masterMerchantLoading = false;
      })
    },
    async fetchData(page = 1) {
      this.isLoading = true;
      if (page) {
        this.page = page
      }
      try {
        const params = {
          page: this.page,
          merchant_ids: this.merchantSelected.map(item => item.id),
          master_merchant_ids: this.masterMerchantSelected.map(item => item.id),
          transaction_code: this.transaction_code,
          identify_code: this.identify_code,
          payment_method: this.payment_method,
          status_nine_s: this.status_nine_s,
          status: this.status,
          date_type: this.date_type,
          date_from: this.date_from,
          date_to: this.date_to,
          amount_to: this.amount_to,
          amount_from: this.amount_from,
        };

        const [{data: responseTransactionData}, {data: reponseTransactionOverviewData}] = await Promise.all([
          apiClient.get('api/cms/virtual-account/transactions', {params}),
          apiClient.get('api/cms/virtual-account/overview', {params}),
        ]);

        if (responseTransactionData.success) {
          this.data_transaction = responseTransactionData.data.data;
          this.data_transaction_paginate = responseTransactionData.data;
        }

        if (reponseTransactionOverviewData.success) {
          this.overview.count_transaction = reponseTransactionOverviewData.data.count_transaction;
          this.overview.count_merchant = reponseTransactionOverviewData.data.count_merchant;
          this.overview.sum_amount = FormatCurrency(reponseTransactionOverviewData.data.sum_amount);
        }
      } catch (e) {
        console.log(e);
        toast("Đã có lỗi xảy ra!", {
          "type": "error",
          "transition": "flip",
          "dangerouslyHTMLString": true
        })
      }
      this.isLoading = false;
    },
    handleSearch() {
      this.fetchData();
    },
    async handleDownloadFile() {
      if (this.isExporting) {
        return;
      }
      toast("Yêu cầu đang được xử lý! Vui lòng không rời khỏi màn hình", {
        "type": "warning",
        "transition": "flip",
        "dangerouslyHTMLString": true
      });

      this.isExporting = true;
      this.progressExport = 0;
      const params = {
        page: this.page,
        merchant_ids: this.merchantSelected.map(item => item.id),
        master_merchant_ids: this.masterMerchantSelected.map(item => item.id),
        transaction_code: this.transaction_code,
        identify_code: this.identify_code,
        payment_method: this.payment_method,
        status_nine_s: this.status_nine_s,
        status: this.status,
        date_type: this.date_type,
        date_from: this.date_from,
        date_to: this.date_to,
        amount_to: this.amount_to,
        amount_from: this.amount_from,
      };

      try {
        let reponsePushAction = await apiClient.get('api/cms/virtual-account/export', {params});
        if (reponsePushAction.data.success) {
          const SELF = this;
          let interval = setInterval(function () {
            apiClient.get('api/cms/virtual-account/export', {params: {...params, key: reponsePushAction.data.data.key}})
                .then(data => data.data.data)
                .then(data => {
                  if (data.progress === 100) {
                    clearInterval(interval);
                    SELF.progressExport = 100;
                    toast("Xuất file thành công!", {
                      "type": "success",
                      "transition": "flip",
                      "dangerouslyHTMLString": true
                    });
                    setTimeout(function () {
                      SELF.isExporting = false;
                    }, 3000);

                    window.location.href = (`${process.env.VUE_APP_PAYMENT_QR_URL}/storage/exports/${data.file_name}?file_name=Thống kê giao dịch.csv`);
                  }
                  SELF.progressExport = data.progress;
                }).catch((e) => {
              console.log(e)
              clearInterval(interval);
              SELF.isExporting = false;
              toast("Xuất file thất bại!", {
                "type": "error",
                "transition": "flip",
                "dangerouslyHTMLString": true
              })
            });
          }, 3000);
        }
      } catch (e) {
        toast("Xuất file thất bại!", {
          "type": "error",
          "transition": "flip",
          "dangerouslyHTMLString": true
        })
        console.log(e);
      }
    }
  },
};
</script>
