<template>
  <div>
    <div class="flex flex-wrap mt-4 table-main">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-5">
        <div class="rounded-t mb-4 py-3 border-0">
          <div class="flex flex-wrap items-center" style="justify-content: space-between;">
            <div class="relative px-4 max-w-full">
              <h3 class="font-semibold text-lg text-blueGray-700">
                Danh sách Config chi phí
              </h3>
            </div>
            <div class="relative  px-4 max-w-full">
              <router-link
                  class="hover:bg-emerald-600 bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  to="/admin/config-fee/create">
                <i class="fas fa-plus"></i> Thêm mới
              </router-link>
            </div>
          </div>
        </div>
        <div class="bg-white block w-full overflow-x-autoflex-auto py-10 pt-0">
          <alert v-if="alertMessage" :message="alertMessage" :type="alertType"></alert>
          <form>
            <div class="max-w-sm w-full lg:max-w-full lg:flex mb-3 mt-3 items-center" style="grid-row-gap: 10px;">
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Đối tượng trả Phí
                  </label>
                  <select v-model="object_type"
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <option value="" selected>Chon đối tượng</option>
                    <option value="1">Merchant</option>
                    <option value="2">User</option>
                  </select>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Loại phí
                  </label>
                  <select v-model="type"
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <option value="" selected>Tất cả</option>
                    <option value="1">Phí thu</option>
                  </select>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Merchant
                  </label>
                  <multiselect
                      v-model="object_id"
                      :options="merchantOptions"
                      placeholder="Vui lòng nhập..."
                      :searchable="true"
                      :loading="merchantLoading"
                      :options-limit="50"
                      open-direction="bottom"
                      track-by="name"
                      label="name"
                      @search-change="searchMerchant"
                  ></multiselect>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Loại dịch vụ
                  </label>
                  <multiselect
                      v-model="service_type_selected"
                      tag-placeholder="Vui lòng lựa chọn"
                      placeholder="Vui lòng chọn ..."
                      label="label"
                      track-by="id"
                      :options="service_types"
                      :multiple="true"
                      :taggable="true"
                  ></multiselect>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Trạng thái Kích hoạt
                  </label>
                  <select v-model="status"
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <option value="" selected>Tất cả</option>
                    <option value="1">Kích hoạt</option>
                    <option value="2">Không kích hoạt</option>
                  </select>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Trạng thái duyệt
                  </label>
                  <select v-model="status_config"
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <option value="" selected>Tất cả</option>
                    <option value="1">Chờ phê duyệt</option>
                    <option value="3">Đã duyệt</option>
                    <option value="2">Từ chối</option>
                  </select>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Hiệu lục từ
                  </label>
                  <input
                      type="date"
                      lang="vi"
                      v-model="effective_from"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Hiệu lực đến
                  </label>
                  <input
                      type="date"
                      lang="vi"
                      v-model="effective_to"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>
            <div class="max-w-sm w-full lg:max-w-full lg:flex mt-3 justify-end">
              <button @click="handleSearch"
                      class="hover:bg-emerald-600 bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-3 rounded shadow outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button">
                <i class="fas fa-search"></i> Tìm kiếm
              </button>
            </div>
          </form>
        </div>
        <div class="block w-full overflow-x-auto relative" style="width: 100%; height: 100%">
          <!-- Projects table -->
          <table class="items-center w-full bg-transparent border-collapse list-config">
            <thead>
            <tr>
              <th
                  class="text-center width10 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                STT
              </th>
              <th
                  class="text-center width10 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                ID
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width10 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Loại Phí
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Đối tượng trả phí
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Tên Merchant
              </th>
              <th
                  class="text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Gói dịch vụ
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Loại dịch vụ
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Hiệu lực
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Trạng thái Kích hoạt
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Trạng thái duyệt
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Hành động
              </th>
            </tr>
            </thead>
            <tbody>
            <tr :style="{backgroundColor: index % 2 === 0 ? '#ecf0f5' : ''}" v-bind:key="index"
                v-for="(config, index) in data_config_fee">
              <td class="text-center border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ index + 1 }}
              </td>
              <td class="text-center border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ config.id }}
              </td>
              <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ config.type?.label }}
              </td>
              <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ config.object_type?.label || "-" }}
              </td>
              <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ config.merchant?.name || "-" }}
              </td>
              <td class="text-center border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                -
              </td>
              <td class="wrap_service_type_labels border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <label class="cf_service_type_label_all" v-if="config.services?.length === totalServiceType">Tất cả dịch
                  vụ</label>
                <label class="cf_service_type_label" v-else v-bind:key="service.value"
                       v-for="service in config.services">{{ service.label }}</label>
              </td>
              <td class="text-center border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ config.effective_from }} - {{ config.effective_to }}
              </td>
              <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <label v-if="config.status?.value === 1" style="color: #29bd5f"
                        >
                  Kích hoạt
                </label>
                <label v-if="config.status?.value === 2 " style="color: #da1212"
                       >
                  Không kích hoạt
                </label>
              </td>
              <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <label v-if="config.status_config?.value === 1" style="color: orange"
                       >
                  Chờ phê duyệt
                </label>
                <label v-if="config.status_config?.value === 2" style="color: #da1212"
                        >
                 Từ chối
                </label>
                <label v-if="config.status_config?.value === 3" style="color: #29bd5f"
                       >
                  Đã duyệt
                </label>

              </td>
              <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                  v-if="config.status_config?.value === 1"
              >
                <div class="flex" style="grid-gap: 15px;flex-direction: column;">
                  <button
                      class="py-2 px-2 bg-lightBlue-500 hover:bg-lightBlue-600 text-white font-semibold rounded-lg shadow-md focus:outline-none">
                    <router-link :to="'/admin/config-fee/' + config.id" style="color: inherit">Chi tiết</router-link>
                  </button>

                  <button style="background: #29bd5f" @click="appoveFee(config.id)"
                          class="py-2 px-2 text-white font-semibold rounded-lg shadow-md focus:outline-none">
                    Duyệt
                  </button>
                  <button
                      @click="rejectFee(config.id)"
                      class="py-2 px-2 bg-red-500 hover:bg-red-500 text-white font-semibold rounded-lg shadow-md focus:outline-none">
                    Từ chối
                  </button>
                </div>
              </td>
              <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                  v-if="config.status_config?.value === 3"
              >
                <div class="flex" style="grid-gap: 15px;flex-direction: column;">
                  <button
                      class="py-2 px-2 bg-lightBlue-500 hover:bg-lightBlue-600 text-white font-semibold rounded-lg shadow-md focus:outline-none">
                    <router-link :to="'/admin/config-fee/' + config.id" style="color: inherit">Chi tiết</router-link>
                  </button>
                </div>
              </td>
              <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                  v-if="config.status_config?.value === 2"
              >
                <div class="flex" style="grid-gap: 15px;flex-direction: column;">
                  <button
                      class="py-2 px-2 bg-lightBlue-500 hover:bg-lightBlue-600 text-white font-semibold rounded-lg shadow-md focus:outline-none">
                    <router-link :to="'/admin/config-fee/' + config.id" style="color: inherit">Chi tiết</router-link>
                  </button>
                  <button style="background: #29bd5f" @click="appoveFee(config.id)"
                          class="py-2 px-2 text-white font-semibold rounded-lg shadow-md focus:outline-none">
                    Duyệt
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
            <tfoot style="height: 50px;">
            <Panigation :dataPage="data_config_fee_paginate" @pageChange:index="fetchData"/>
            </tfoot>
          </table>
          <div v-if="data_config_fee.length === 0" class="items-center w-full bg-transparent border-collapse"
               style="height: 150px;">
            <i>Không có dữ liệu!</i>
          </div>
          <div v-if="isLoading" class="loading absolute loading_table">
            <img :src="loadingImage" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.table-main table tbody {
  font-size: 22px;
  font-weight: 500;
}

.cf_service_type_label {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #10b981;
  color: #10b981;
}

.cf_service_type_label_all {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #f97316;
  color: #f97316;
}

.list-config .wrap_service_type_labels {
  display: flex;
  grid-column-gap: 8px;
  flex-wrap: wrap;
  grid-row-gap: 7px;
}
</style>

<script>

import apiClient from '@/services/api.js';
import Alert from "@/components/Alerts/Alert.vue"
import ServiceTypes from "@/config/fees/service-type";
import Multiselect from "vue-multiselect";
import {debounce} from 'vue-debounce'
import Panigation from "@/components/Panigation.vue";
import loadingImage from "@/assets/img/loading.gif";
import {toast} from 'vue3-toastify';

const debounceFn = debounce(callback => callback(), '400ms')

export default {
  data() {
    return {
      alertMessage: '',
      alertType: 'info',
      type: '',
      object_type: '',
      service_types: ServiceTypes,
      service_type_selected: [],
      effective_from: '',
      effective_to: '',
      object_id: null,
      merchantOptions: [],
      merchantLoading: false,
      data_config_fee: [],
      data_config_fee_paginate: [],
      status: '',
      status_config: '',
      page: null,
      loadingImage,
      isLoading: false,
      totalServiceType: ServiceTypes.length
    };
  },
  components: {
    Panigation,
    Multiselect,
    Alert,
  },
  props: {
    //
  },
  created() {
    this.searchMerchant();
    this.fetchData();
  },
  watch: {
    //
  },
  methods: {
    searchMerchant(query) {
      debounceFn(async () => {
        this.merchantLoading = true;
        const self = this;
        try {
          let {data} = await apiClient.get('api/merchant/search', {
            params: {keyword: query}
          })
          self.merchantLoading = false;
          if (data.status) {
            self.merchantOptions = data.data;
          }
        } catch (e) {
          console.log(e);
          toast(e?.response?.data?.message || "Đã có lỗi xảy ra!", {
            "type": "error",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
        }
      })
    },
    async fetchData(page = 1) {
      this.isLoading = true;
      if (page) {
        this.page = page
      }
      try {

        let {data} = await apiClient.get('api/fee', {
          params: {
            page: this.page,
            object_type: this.object_type,
            type: this.type,
            object_id: this.object_id?.id,
            service_types: this.service_type_selected.map(item => item.id),
            status: this.status,
            effective_from: this.effective_from,
            effective_to: this.effective_to,
            status_config: this.status_config
          }
        });
        if (data.status) {
          this.data_config_fee = data.data.data;
          this.data_config_fee_paginate = data.data;
        }
      } catch (e) {
        console.log(e);
        toast(e?.response?.data?.message || "Đã có lỗi xảy ra!", {
          "type": "error",
          "transition": "flip",
          "dangerouslyHTMLString": true
        })
      }
      this.isLoading = false;
    },
    async appoveFee(id) {
      let conf = confirm("Bạn có chắc chắn duyệt yêu cầu này không?");

      if (conf === false) {
        return true;
      }
      try {
        this.isLoading = true;
        let data = await apiClient.post(`api/fee/approve/`, {id: id});
        if (data.data.error_type === 'api' ) {
          toast("Bạn không có quyền duyệt!", {
            "type": "warning",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
          this.isLoading = false;
        }
        else {
          toast("Đã duyệt phí thành công", {
            "type": "success",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }

      } catch (e) {
        toast("Có lỗi xả ra trong quá trình", {
          "type": "error",
          "transition": "flip",
          "dangerouslyHTMLString": true
        })
      }
    },
    async rejectFee(id) {
      let conf = confirm("Bạn có chắc chắn từ chối yêu cầu này không?");

      if (conf === false) {
        return true;
      }
      try {
        this.isLoading = true;
        let data = await apiClient.post(`api/fee/reject/`, {id: id});
        if (data.data.error_type === 'api' ) {
          toast("Bạn không có quyền duyệt!", {
            "type": "warning",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
          this.isLoading = false;
        } else {
          toast("Yêu cầu từ chối thành công", {
            "type": "success",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }

      } catch (e) {
        toast("Có lỗi xả ra trong quá trình", {
          "type": "error",
          "transition": "flip",
          "dangerouslyHTMLString": true
        })
      }
    },
    handleSearch() {
      this.fetchData();
    }
  },
};
</script>
