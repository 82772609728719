/*
* truongnq@9pay.vn
* */

import axios from 'axios'
import WebStorage from '@/services/webStorage'
import { handleError } from '@/services/handleError'

const webLocalStorage = new WebStorage('local')
const apiClient = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': process.env.VUE_APP_URL,
        'Access-Control-Allow-Credentials': "true",
        'Access-Control-Max-Age': "1800",
        'Access-Control-Allow-Headers': "origin, content-type, accept"
    },
})

apiClient.interceptors.request.use(
    (requestConfig) => {
        const token = webLocalStorage.get('sdk_token')
        if (token && token?.access_token) {
            requestConfig.headers.Authorization = `Bearer ${token.access_token}`
        }

        return requestConfig
    }
)

apiClient.interceptors.response.use(function(response) {
    return response
}, handleError)

export default apiClient