<template>
  <div class="flex flex-wrap mt-4 table-main">
    <Notify :showNotice="showNotice" :messErr="messErr" :messageNotice="messageNotice"></Notify>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
         :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center" style="justify-content: space-between;">
          <div class="relative px-4 max-w-full">
            <h3 class="font-semibold text-lg" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
              {{ $route.params.id ? 'Chỉnh sửa ' : 'Thêm mới ' }}thông tin vai trò
            </h3>
          </div>
          <div class="relative px-4 max-w-full">
            <a
                class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                href="/admin/role/index"
            >
              <i class="fa fa-arrow-left"></i> Quay lại
            </a>
          </div>
        </div>
      </div>
      <div class="block w-full overflow-x-auto">
        <div class="ml-4 mb-2">
          <div
              class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none blur-form">
            <div class="flex-auto px-4 lg:px-10 pt-10 pt-0">
              <form>
                <div class="flex flex-wrap p-4">
                  <div class="w-full px-3">
                    <div class="relative w-full mb-3">
                      <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                        Tên vai trò
                      </label>
                      <input type="text"
                             class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                             v-model="formData.name" placeholder="Điền tên tài khoản" style="margin-bottom: 6px;"
                             :maxlength="maxCharacters" @input="removeSpaces"/>
                      <span v-if="v$.formData.name.$error" style="color:red"> {{
                          v$.formData.name.$errors[0].$message
                        }} </span>
                    </div>
                  </div>
                  <div class="w-full px-4">
                    <div class="relative w-full mb-3">
                      <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                        Mô tả
                      </label>
                      <input type="text"
                             class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                             v-model="formData.description" placeholder="Mô tả" style="margin-bottom: 6px;"/>
                      <span v-if="v$.formData.description.$error"
                            style="color:red"> {{ v$.formData.description.$errors[0].$message }} </span>
                    </div>
                  </div>
                  <div class="w-full px-4">
                    <div class="relative w-full mb-3">
                      <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                        Quyền
                      </label>

                      <div v-for="(group, index) in permissions" :key="index" class="role-block p-4">
                        <div class="role-header">
                          <label class="role-label">
                            {{index}}
                          </label>
                        </div>

                        <!-- Nội dung của role, chỉ hiển thị khi role được mở -->
                        <transition name="slide-fade">
                          <div class="permissions-list">
                            <div v-for="permission in group" :key="permission.id" class="permission-item">
                              <label class="permission-name">
                                <input
                                    type="checkbox"
                                    v-model="permission.selected"
                                />
                                {{ permission.name }} - {{ permission.description }}
                              </label>
                            </div>
                          </div>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!--footer-->
            <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b p-4">
              <button
                  :disabled="disableButton"
                  class="bg-emerald-500 active:bg-emerald-600 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button" v-on:click="save()">
                Đồng ý
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Định dạng các khối chính */
.role-block {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

/* Định dạng tiêu đề của role */
.role-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
}

/* Định dạng checkbox và nhãn */
.role-label {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.role-label input {
  margin-right: 10px;
}
</style>

<script>
import apiClient from "@/services/api";
import Notify from "@/components/Notification/Notify.vue";
import useValidate from '@vuelidate/core'
import {required, minLength, helpers} from '@vuelidate/validators'
import {toast} from "vue3-toastify";

export default {
  components: {Notify},
  data() {
    return {
      v$: useValidate(),
      showModal: false,
      myValue: '',
      formData: {
        name: '',
        description: '',
        permissions: [],
      },
      permissionIds: [],
      showNotice: false,
      messageNotice: '',
      messErr: false,
      maxCharacters: 50,
      permissions: [],
      disableButton: false
    };
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  async created() {
    this.formData.permissions = [];
    if (this.$route.params.id) {
      await this.getRole()
    } else {
      this.permissionIds = [];
      this.formData.name = '';
      this.formData.description = '';
    }

    await this.getPermissions()
  },

  methods: {
    removeSpaces() {
      // Loại bỏ dấu cách từ giá trị đầu vào
      this.inputText = this?.inputText.replace(/\s/g, "");
    },

    async getRole() {
      const response = await apiClient.get('api/permission-manager/role', {
        params: {
          ids: [this.$route.params.id],
          page: 1,
          limit: 1
        }
      });

      if (response.data.error_code !== 0) {
        toast(response?.data?.message || "Đã có lỗi xảy ra!", {
          "type": "error",
          "transition": "flip",
          "dangerouslyHTMLString": true
        })
        this.disableButton = true;
      } else {
        this.disableButton = false;
        this.messErr = false;
        this.messageNotice = response.data.message;
        this.showNotice = true;

        const itemData = response.data.data[0]
        if (itemData) {
          this.formData.name = itemData.name;
          this.formData.description = itemData.description;
          this.permissionIds = itemData.permissions.map(function (permission) {
            return permission.id
          });
        } else {
          this.disableButton = true;
        }
      }
    },

    save() {
      this.v$.$validate()
      if (this.v$.$error) {
        return false;
      }

      this.formData.permissions = [];
      Object.values(this.permissions).forEach(groupArray => {
        groupArray.forEach(permission => {
          if (permission.selected === true) {
            this.formData.permissions.push(permission.id);
          }
        });
      });

      const idData = this.$route.params.id

      if (idData) {
        apiClient.put('api/permission-manager/role/update/' + idData, this.formData)
            .then(response => {
              if (response.data.error_code !== 0) {
                toast(response?.data?.message || "Đã có lỗi xảy ra!", {
                  "type": "error",
                  "transition": "flip",
                  "dangerouslyHTMLString": true
                })
              } else {
                this.messErr = false;
                this.messageNotice = response.data.message;
                this.showNotice = true;

                this.getRole()
              }
            })
            .catch(error => {
              toast(error?.response?.data?.message || "Đã có lỗi xảy ra!", {
                "type": "error",
                "transition": "flip",
                "dangerouslyHTMLString": true
              })
              console.error('Đã có lỗi xảy ra!', error);
            });
      } else {
        apiClient.post('api/permission-manager/role', this.formData)
            .then(response => {
              if (response.data.error_code !== 0) {
                toast(response?.data?.message || "Đã có lỗi xảy ra!", {
                  "type": "error",
                  "transition": "flip",
                  "dangerouslyHTMLString": true
                })
              } else {
                this.messErr = false;
                this.messageNotice = response.data.message;
                this.showNotice = true;

                window.location.href = '/admin/role/action/' + response.data.data.id;
              }
            })
            .catch(error => {
              toast(error?.response?.data?.message || "Đã có lỗi xảy ra!", {
                "type": "error",
                "transition": "flip",
                "dangerouslyHTMLString": true
              })
              console.error('Đã có lỗi xảy ra!', error);
            });
      }
    },
    async getPermissions() {
      this.loading = true
      try {
        apiClient.get('api/permission-manager/permission', {
          params: { is_group: true }
        })
            .then(response => {
              if (response.data.error_code !== 0) {
                toast(response?.data?.message || "Đã có lỗi xảy ra!", {
                  "type": "error",
                  "transition": "flip",
                  "dangerouslyHTMLString": true
                })
              }
              const permissionIds = this.permissionIds

              this.permissions = response.data.data
              Object.values(this.permissions).forEach(groupArray => {
                groupArray.forEach(permission => {
                  permission.selected = permissionIds.includes(permission.id);
                });
              });
            })
            .catch(error => {
              toast(error?.response?.data?.message || "Đã có lỗi xảy ra!", {
                "type": "error",
                "transition": "flip",
                "dangerouslyHTMLString": true
              })
              console.error('Đã có lỗi xảy ra!', error);
            });
      } catch (error) {
        toast(error?.response?.data?.message || "Đã có lỗi xảy ra!", {
          "type": "error",
          "transition": "flip",
          "dangerouslyHTMLString": true
        })
        if (error.response?.status === 401) {
          console.error('Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!', 'error')
        } else {
          console.error('Không lấy được danh sách vị trí làm việc!', 'error')
        }
      } finally {
        this.loading = false
      }
    },
  },

  validations() {
    return {
      formData: {
        name: {
          required: helpers.withMessage('Vui lòng nhập tên vai trò', required),
          minLength: helpers.withMessage('Vui lòng nhập từ 5->255 ký tự', minLength(5)),
        },
        description: {
          required: helpers.withMessage('Vui lòng nhập mô tả', required),
          minLength: helpers.withMessage('Vui lòng nhập từ 5->255 ký tự', minLength(5)),
        },
      },
    }
  },
};
</script>
