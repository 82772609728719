<template>
  <form id="form_config_fee" @submit.prevent="submitForm($event)">
    <div class="flex flex-wrap" style="grid-row-gap: 10px;">
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Tên công ty (Master Merchant) <small class="dot-red-label"></small>
          </label>
          <input
              v-if="is_updated"
              type="text"
              v-model="merchant_data.merchant_info.name"
              placeholder="Nhập tên master merchant"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
          <multiselect
              v-else
              v-model="merchant_data.merchant_info"
              :options="merchantOptions"
              placeholder="Thêm mới hoặc tìm kiếm merchant"
              tag-placeholder="Tạo mới với merchant này"
              :taggable="true"
              @tag="addNewMerchant"
              :searchable="true"
              :disabled="disableProperty"
              :loading="merchantLoading"
              :options-limit="50"
              open-direction="bottom"
              track-by="id"
              label="name"
              @search-change="searchMerchant"
          ></multiselect>
        </div>
      </div>
      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            MST
          </label>
          <input
              :disabled="disableProperty || disableParentMerchant"
              type="text"
              v-model="merchant_data.tax_code"
              placeholder="Nhập mã số thuế"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Người đại diện
          </label>
          <input
              :disabled="disableProperty || disableParentMerchant"
              type="text"
              v-model="merchant_data.agent"
              placeholder="Nhập người đại diện"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>

      <div class="w-full lg:w-12/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Địa chỉ trụ sở
          </label>
          <input
              :disabled="disableProperty || disableParentMerchant"
              type="text"
              v-model="merchant_data.master_merchant_address"
              placeholder="Nhập địa chỉ trụ sở"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>

      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Master Merchant Code <small class="dot-red-label"></small>
          </label>
          <input
              :disabled="disableProperty || disableParentMerchant || is_updated"
              type="text"
              v-model="merchant_data.master_merchant_code"
              placeholder="Nhập master Merchant Code"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Password <small class="dot-red-label"></small>
          </label>
          <input
              :disabled="disableProperty || disableParentMerchant || is_updated"
              type="password"
              v-model="merchant_data.master_merchant_password"
              placeholder="Nhập password"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>

      <div v-if="is_updated" class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Master Merchant Key <small class="dot-red-label"></small>
          </label>
          <input
              :disabled="true"
              type="text"
              v-model="meta.master_merchant_key"
              placeholder="Nhập master Merchant Code"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
      <div v-if="is_updated" class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Secret Key <small class="dot-red-label"></small>
          </label>
          <input
              :disabled="true"
              type="text"
              v-model="meta.master_secret_key"
              placeholder="Secret Key"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>

      <div class="w-full px-4 lg:w-12/12">
        <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
        >
          Đối tác có nhiều chi nhánh?
        </label>
        <div class="flex" style="grid-column-gap: 50px;">
          <div class="flex items-center gap-x-3">
            <input :disabled="disableProperty || disableParentMerchant || meta.root_is_has_children || (is_updated && merchant_data.is_has_children == 0 && merchant_data.status == 2)" v-model="merchant_data.is_has_children" id="is_has_children" name="is_has_children" value="1" type="radio" class="cursor-pointer h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            <label for="is_has_children" class="cursor-pointer ml-2 block text-sm font-medium leading-6 text-gray-900">Có</label>
          </div>
          <div class="flex items-center gap-x-3">
            <input :disabled="disableProperty || disableParentMerchant || meta.root_is_has_children || (is_updated && merchant_data.is_has_children == 0 && merchant_data.status == 2)" v-model="merchant_data.is_has_children" id="is_not_has_children" name="is_has_children" value="0" type="radio" class="cursor-pointer h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
            <label for="is_not_has_children" class="cursor-pointer ml-2 block text-sm font-medium leading-6 text-gray-900">Không</label>
          </div>
        </div>
      </div>
      <div v-if="merchant_data.is_has_children == 1" class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Tên merchant <small class="dot-red-label"></small>
          </label>
          <input
              :disabled="disableProperty"
              type="text"
              v-model="merchant_data.merchant_name"
              placeholder="Nhập tên merchant"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
      <div v-if="merchant_data.is_has_children == 1" class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Merchant code <small class="dot-red-label"></small>
          </label>
          <input
              :disabled="disableProperty || (is_updated && meta.root_is_has_children)"
              type="text"
              v-model="merchant_data.merchant_code"
              placeholder="Nhập merchant code"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
      <div v-if="merchant_data.is_has_children == 1" class="w-full lg:w-12/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Địa chỉ chi nhánh
          </label>
          <input
              :disabled="disableProperty"
              type="text"
              v-model="merchant_data.merchant_address"
              placeholder="Nhập địa chỉ chi nhánh"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>

      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            INP Link <small class="dot-red-label"></small>
          </label>
          <input
              :disabled="disableProperty"
              type="url"
              v-model="merchant_data.merchant_ipn"
              placeholder="Nhập INP link"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Trạng thái <small class="dot-red-label"></small>
          </label>
          <select :disabled="disableProperty" v-model="merchant_data.status" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
            <option value="1" selected>Hoạt động</option>
            <option value="2">Dừng hoạt động</option>
          </select>
        </div>
      </div>

      <div v-if="is_updated && meta.root_is_has_children" class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Merchant Key <small class="dot-red-label"></small>
          </label>
          <input
              :disabled="true"
              type="text"
              v-model="meta.merchant_key"
              placeholder="Nhập master Merchant Code"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
      <div v-if="is_updated && meta.root_is_has_children" class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Secret Key <small class="dot-red-label"></small>
          </label>
          <input
              :disabled="true"
              type="text"
              v-model="meta.secret_key"
              placeholder="Secret Key"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
    </div>
    <div v-if="!is_updated" class="w-full lg:w-12/12 px-4 text-center">
      <strong style="color: #10b981">Hoặc</strong>
    </div>
    <div v-if="!is_updated" class="flex flex-wrap" style="grid-row-gap: 10px;">
      <div class="w-full lg:w-12/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Thêm thông tin nhiều merchant
          </label>
          <DropFile @change:file="handleUpdateFile"></DropFile>
        </div>
      </div>
    </div>
  </form>
</template>
<style>
.config_service_block {
  margin: 0 15px;
}
</style>
<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import apiClient from "@/services/api";
import {debounce} from 'vue-debounce'
import DropFile from "@/components/Tools/DropFileMerchantQR.vue";

const debounceFn = debounce(callback => callback(), '400ms')

export default {
  data() {
    return {
      merchantLoading: false,
      merchantOptions: [],
      disableProperty: false,
      disableParentMerchant: false,
      merchant_data: {
        merchant_info: {},
        tax_code: '',
        agent: '',
        master_merchant_address: '',
        master_merchant_code: '',
        master_merchant_password: '',
        is_has_children: 1,
        merchant_name: '',
        merchant_code: '',
        merchant_address: '',
        merchant_ipn: '',
        status: 1,
      },
      meta: {
        master_merchant_key: '',
        master_secret_key: '',
        merchant_key: '',
        secret_key: '',
        root_is_has_children: 0,
      },
      file: null,
      is_updated: false,
    };
  },
  components: {
    Multiselect,
    DropFile
  },
  props: {
    merchantData: {
      type: Object
    }
  },
  created() {
    this.searchMerchant();
    if (!this.merchantData) {
      const SELF = this;
      setTimeout(function () {
        SELF.merchant_data.merchant_info.id = null;
        SELF.merchant_data.merchant_info.name = '';
      }, 500);

      return;
    }

    this.is_updated = true;
    if (!this.merchantData.parent_merchant) {
      this.merchant_data.merchant_info.id = this.merchantData.id;
      this.merchant_data.merchant_info.name = this.merchantData.name;
      this.merchant_data.tax_code = this.merchantData.tax_code;
      this.merchant_data.agent = this.merchantData.agent;
      this.merchant_data.master_merchant_address = this.merchantData.address;
      this.merchant_data.master_merchant_code = this.merchantData.merchant_code;
      this.merchant_data.is_has_children = this.merchantData.is_has_children;
      this.merchant_data.master_merchant_password = '*************';
      this.meta.master_merchant_key = this.merchantData.merchant_key;
      this.meta.master_secret_key = this.merchantData.secret_key;
      this.meta.root_is_has_children = this.merchantData.is_has_children;
    } else {
      this.merchant_data.merchant_info.id = this.merchantData.parent_merchant.id;
      this.merchant_data.merchant_info.name = this.merchantData.parent_merchant.name;
      this.merchant_data.tax_code = this.merchantData.parent_merchant.tax_code;
      this.merchant_data.agent = this.merchantData.parent_merchant.agent;
      this.merchant_data.master_merchant_address = this.merchantData.parent_merchant.address;
      this.merchant_data.master_merchant_code = this.merchantData.parent_merchant.merchant_code;
      this.merchant_data.master_merchant_password = '*************';
      this.merchant_data.is_has_children = this.merchantData.parent_merchant.is_has_children;
      this.meta.root_is_has_children = this.merchantData.parent_merchant.is_has_children;
      this.meta.master_merchant_key = this.merchantData.parent_merchant.merchant_key;
      this.meta.master_secret_key = this.merchantData.parent_merchant.secret_key;
      this.merchant_data.merchant_name = this.merchantData.name;
      this.merchant_data.merchant_code = this.merchantData.merchant_code;
      this.merchant_data.merchant_address = this.merchantData.address;
      this.meta.merchant_key = this.merchantData.merchant_key;
      this.meta.secret_key = this.merchantData.secret_key;
    }

    this.merchant_data.merchant_ipn = this.merchantData.ipn_url;
    this.merchant_data.status = this.merchantData.status;
  },
  watch: {
    "merchant_data.merchant_info": function (newValue) {
      if (!newValue) {
        this.disableParentMerchant = false;
        this.merchant_data.tax_code = '';
        this.merchant_data.agent = '';
        this.merchant_data.master_merchant_address = '';
        this.merchant_data.master_merchant_code = '';
        this.merchant_data.master_merchant_password = '';
        return;
      }

      if (newValue.is_new !== true) {
        this.disableParentMerchant = true;
        this.merchant_data.tax_code = newValue.tax_code;
        this.merchant_data.agent = newValue.agent;
        this.merchant_data.master_merchant_address = newValue.address;
        this.merchant_data.master_merchant_code = newValue.merchant_code;
        this.merchant_data.master_merchant_password = '******************';
        this.merchant_data.is_has_children = 1;
        return;
      }

      this.disableParentMerchant = false;
      this.merchant_data.tax_code = '';
      this.merchant_data.agent = '';
      this.merchant_data.master_merchant_address = '';
      this.merchant_data.master_merchant_code = '';
      this.merchant_data.master_merchant_password = '';
    },
    file(data) {
      this.disableProperty = !!data;
    }
  },
  methods: {
    searchMerchant(query) {
      debounceFn(async () => {
        this.merchantLoading = true;
        const self = this;
        try {
          let { data } = await apiClient.get( process.env.VUE_APP_PAYMENT_QR_URL + '/api/internal/merchant/search-parent', {
            params: {keyword: query}
          })
          self.merchantLoading = false;

          if (data.success) {
            self.merchantOptions = data.data;
          }
        } catch (e) {
          console.log(e);
        }
      })
    },
    submitForm(event) {
      event.preventDefault();
      if (this.file) {
        this.$emit('submit-file', {
          file: this.file
        });
        return
      }
      this.$emit('submit-form', {
        master_merchant_name: this.merchant_data.merchant_info?.name || '',
        master_merchant_id: (this.merchant_data.merchant_info?.is_new && !this.is_updated) ? null : this.merchant_data.merchant_info.id,
        master_merchant_tax_code: this.merchant_data.tax_code,
        master_merchant_agent: this.merchant_data.agent,
        master_merchant_address: this.merchant_data.master_merchant_address,
        master_merchant_code: this.merchant_data.master_merchant_code,
        master_merchant_password: this.merchant_data.master_merchant_password,
        is_has_children: this.merchant_data.is_has_children,
        merchant_name: this.merchant_data.merchant_name,
        merchant_code: this.merchant_data.merchant_code,
        merchant_address: this.merchant_data.merchant_address,
        merchant_ipn: this.merchant_data.merchant_ipn,
        status: this.merchant_data.status,
      });
    },
    addNewMerchant(newMerchant) {
      const tag = {
        name: newMerchant,
        id: Math.floor((Math.random() * 10000000)),
        tax_code: "",
        address: "",
        parent_id: null,
        agent: null,
        merchant_code: "",
        is_new: true
      }

      this.merchant_data.merchant_info = tag;
      this.merchantOptions.push(tag)
    },
    handleUpdateFile(file) {
      this.file = file;
    }
  },
};
</script>
