<template>
  <div>
    <div class="flex flex-wrap mt-4 table-main">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-5">
        <div class="rounded-t mb-4 px-4 py-3 border-0">
          <div class="flex flex-wrap items-center" style="justify-content: space-between;">
            <div class="relative px-4 max-w-full">
              <h3 class="font-semibold text-lg text-blueGray-700">
                Config VAS
              </h3>
            </div>
          </div>
        </div>
        <div class="bg-white block w-full overflow-x-autoflex-auto px-4 lg:px-10 py-10 pt-0">
          <alert v-if="alertMessage" :message="alertMessage" :type="alertType"></alert>
          <form-config-vas @submit-form="handleStoreConfig"></form-config-vas>
        </div>
        <div class="flex flex-wrap mt-4 table-main action_button_form_fee">
          <button type="submit" form="form_config_vas" class="py-2 px-8 bg-emerald-500 hover:bg-emerald-600 text-white font-semibold rounded-lg shadow-md focus:outline-none">Lưu</button>
          <button @click="reload" type="button" form="form_config_fee" class="py-2 px-8 bg-lightBlue-500 hover:bg-lightBlue-600 text-white font-semibold rounded-lg shadow-md focus:outline-none">Làm mới</button>
          <button @click="cancelAction" type="button" form="form_config_fee" class="py-2 px-8 bg-red-500 hover:bg-red-600 text-white font-semibold rounded-lg shadow-md focus:outline-none">Huỷ</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLoading" class="loading absolute loading_table" >
    <img :src="loadingImage" alt="">
  </div>
</template>
<style>
.table-main table tbody {
  font-size: 22px;
  font-weight: 500;
}
</style>

<script>

import FormConfigVas from "@/components/Vas/FormConfigVas.vue";
import apiClient from '@/services/api.js';
import Alert from "@/components/Alerts/Alert.vue"
import {toast} from "vue3-toastify";
import loadingImage from "@/assets/img/loading.gif";

export default {
  data() {
    return {
      alertMessage: '',
      alertType: 'info',
      loadingImage,
      isLoading: false,
    };
  },
  components: {
    FormConfigVas,
    Alert,
  },
  props: {
    //
  },
  created() {
    //
  },
  watch: {
    //
  },
  methods: {
    async handleStoreConfig(configData) {
      try {
        this.isLoading = true;
        this.alertMessage = '';
        let response = await apiClient.post('api/config-vas/store', {
          ...configData
        });
        if (response.data.status) {
          this.alertMessage = response.data.message;
          this.alertType = 'success';
          this.isLoading = false;
          window.scroll({
            top: 10,
            left: 10,
            behavior: "smooth",
          });
          let self = this;
          setTimeout(function () {
            self.$router.push({ path: '/admin/tool/config-vas' });
          }, 500);
        } else {
          toast(response.data.message || 'Thêm mới thất bại. Vui lòng thử lại sau!', {
            "type": "error",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
          this.isLoading = false;
        }
      } catch (e) {
        toast(e?.response?.data?.message || "Đã có lỗi xảy ra!", {
          "type": "error",
          "transition": "flip",
          "dangerouslyHTMLString": true
        })
        this.isLoading = false;
        console.log(e)
      }
    },
    backToList() {
       this.$router.push({ path: '/admin/tool/config-vas' });
    },
    cancelAction() {
      if (confirm('Config sẽ không được lưu nếu bạn rời khỏi trang!')) {
        this.backToList();
      }
    },
    reload() {
      location.reload()
    }
  },
};
</script>
