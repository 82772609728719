<template>
  <div>
  <Notify :showNotice = "showNotice" :messErr = "messErr" :messageNotice="messageNotice"></Notify>
  <div class="flex flex-wrap mt-4 table-main">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center" style="justify-content: space-between;">
          <div class="relative px-4 max-w-full">
            <h3 class="font-semibold text-lg" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
              Quản lý thông tin Quyền
            </h3>
          </div>
          <div class="relative  px-4 max-w-full">
          </div>
        </div>
      </div>
      <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">
                STT
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">
                Tên
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">
                Mô tả
              </th>
              <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">
                Nhóm
              </th>
              <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">
                Ngày tạo
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]">
              </th>
            </tr>
          </thead>
          <tbody>
          <tr :style="{backgroundColor: index % 2 === 0 ? '#ecf0f5' : ''}" v-bind:key="index" v-for="(item, index) in dataTable">
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                <span class="ml-3 font-bold" :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white',
                ]">
                  {{ index + 1 }}
                </span>
              </th>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ item.name }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ item.description }}
              </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ item.group }}
            </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ item.created_at }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                <button
                  class="text-lightBlue-500 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button" v-on:click="toggleModal('edit', item)">
                  <i class="fas fa-edit" style="font-size: 18px;"></i>
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot style="height: 50px;">
              <Panigation :dataPage="dataUser" @pageChange:index="fetchData"/>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <div>
    <formAdmin v-if="showModal" :groups="groups" :activeForm="showModal" @update:activeForm="updateActiveForm" :typeForm="form" :itemData="itemData">
    </formAdmin>
  </div>
</div></template>
<style>
.table-main table tbody {
  font-size: 22px;
  font-weight: 500;
}

.main-boss {
  display: grid;
  grid-template-rows: 0fr;
}

.overlay-1 {
  opacity: 0.3;
  transition: opacity 0.3s ease;
}

.overlay-2 {
  opacity: 0.6;
  transition: opacity 0.3s ease;
}
</style>
<script>
import Panigation from "@/components/Panigation.vue";
import formAdmin from './formAdmin.vue';
import apiClient from '@/services/api.js';

import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";

import Notify from "@/components/Notification/Notify.vue"
import {toast} from "vue3-toastify";


export default {
  data() {
    return {
      bootstrap,
      angular,
      sketch,
      react,
      vue,
      showModal: false,
      dataTable: [],
      dataUser: [],
      form: '',
      itemData: '',
      progress: 0, // Giả sử bạn có một biến progress để theo dõi tiến trình
      limit: 10,
      page:null,
      messageNotice: '',
      messErr: false,
      showNotice: false,
      groups: [],
    };
  },
  components: {
    formAdmin,
    Panigation,
    Notify
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    toggleModal(value, data) {
      this.form = value;
      this.itemData = data;
      this.showModal = !this.showModal;
      if (this.showModal) {
        document.getElementById("main-content").classList.add("overlay-1");
      }
    },

    updateActiveForm(value, type = 'create') {
      this.showModal = value;
      if (!this.showModal) {
        document.getElementById("main-content").classList.remove("overlay-1");
      }

      if (type === 'create') {
        this.fetchData(1)
      } else if (type === 'edit') {
        this.fetchData(this.page)
      }
    },

    fetchData(value) {
      if(value) this.page = value;
      else this.page = 1;
      apiClient.get('api/permission-manager/permission?page='+ this.page || 1 +'&limit=' + this.limit)
        .then(response => {
          if (response.data.error_code !== 0) {
            toast(response?.data?.message || "Đã có lỗi xảy ra!", {
              "type": "error",
              "transition": "flip",
              "dangerouslyHTMLString": true
            })
            return
          }
          this.dataTable = response?.data?.data || [];
          this.dataUser = response?.data || [];
          this.groups = Object.values(response?.data?.groups).map(value => ({
            key: value,
            value: value
          }));
        })
        .catch(error => {
          toast(error?.response?.data?.message || "Đã có lỗi xảy ra!", {
            "type": "error",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })

          console.error('Đã có lỗi xảy ra!', error);
        });
    },
  },
};
</script>
