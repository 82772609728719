<template>
  <div>
    <div class="flex flex-wrap mt-4 table-main">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-5">
        <div class="rounded-t mb-4 px-4 py-3 border-0">
          <div class="flex flex-wrap items-center" style="justify-content: space-between;">
            <div class="relative px-4 max-w-full">
              <h3 class="font-semibold text-lg text-blueGray-700">
                Chi tiết giao dịch
              </h3>
            </div>
          </div>
        </div>
        <div class="block_transition_detail bg-white block w-full flex flex-wrap mb-3 mt-3 px-4 lg:px-10 py-10 pt-0 items-start" v-if="transaction_detail">
          <div class="w-full lg:w-6/12">
            <table class="items-center bg-transparent border-collapse mr-3">
              <thead>
              <tr>
                <th colspan="2" style="background-color: #09ad7a; color: #fff; height: 50px;"
                    class="text-center width10 px-2 align-middle border border-solid py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Chi tiết giao dịch
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Mã đơn hàng</th>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">{{ transaction_detail?.transaction?.virtual_account?.identify_code || '-'  }}</td>
              </tr>
              <tr>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Mã giao dịch</th>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">{{ transaction_detail?.transaction?.virtual_account?.transaction_code || '-'  }}</td>
              </tr>
              <tr>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Ngân hàng nhận tiền</th>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">{{ transaction_detail?.transaction?.virtual_account?.bank_name || '-'  }}</td>
              </tr>
              <tr>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Mã VA</th>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">{{ transaction_detail?.transaction?.virtual_account?.code || '-'  }}</td>
              </tr>
              <tr>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Số tiền</th>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">{{ FormatCurrency(transaction_detail?.transaction?.virtual_account?.amount || 0)  }}</td>
              </tr>
              <tr>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Nội dung</th>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">{{ transaction_detail?.transaction?.virtual_account?.content || '-'  }}</td>
              </tr>
              <tr>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Hình thức thanh toán</th>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">{{ transaction_detail?.payment_method_name || '-' }}</td>
              </tr>
              <tr>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Mã GD 9Pay</th>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">{{ transaction_detail?.transaction?.code || '-'  }}</td>
              </tr>
              <tr>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Trạng thái 9S</th>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">{{ transaction_detail?.transaction?.status_nine_s_str || '-'  }}</td>
              </tr>
              <tr>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Trạng thái đơn hàng</th>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">{{ transaction_detail?.transaction?.status_invoice_str || '-'  }}</td>
              </tr>
              <tr>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Nguyên nhân thất bại</th>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">-</td>
              </tr>
              <tr>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Thời gian khởi tạo</th>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">{{ transaction_detail?.transaction?.created_at || '-' }}</td>
              </tr>
              <tr>
                <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Thời gian thanh toán</th>
                <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">{{ transaction_detail?.transaction?.complete_at || '-' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="w-full lg:w-6/12">
            <table class="items-center bg-transparent border-collapse ml-3">
              <thead>
              <tr>
                <th colspan="2" style="background-color: #09ad7a; color: #fff; height: 50px;"
                    class="text-center width10 px-2 align-middle border border-solid py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Thông tin merchant
                </th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Tên Merchant</th>
                  <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">{{ transaction_detail?.merchant?.name || '-'  }}</td>
                </tr>
                <tr>
                  <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Merchant_code</th>
                  <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">{{ transaction_detail?.merchant?.merchant_code || '-'  }}</td>
                </tr>
                <tr>
                  <th class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-left">Master merchant </th>
                  <td class="border-t-0 px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4">{{ transaction_detail?.merchant?.parent_merchant?.name || '-'  }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.table-main table tbody {
  //font-size: 22px;
  //font-weight: 500;
}
.block_transition_detail table th {
  font-size: 15px;
  font-weight: 500;
}
.block_transition_detail table tr td {
  font-weight: 300;
  font-size: 15px !important;
}
.block_transition_detail table tr:nth-child(even) {
  background-color: #ecf0f5;
}
.block_transition_detail table {
  border: 1px solid #09ad7a;
}
</style>

<script>
import apiClient from '@/services/api.js';
import { toast } from 'vue3-toastify';
import {FormatCurrency} from "@/utils/numberUtils";

export default {
  data() {
    return {
      transaction_detail: null
    };
  },
  components: {
  },
  props: {
    //
  },
  async created() {
    try {
      let { data } = await apiClient.get('api/cms/virtual-account/' + this.$route.params.id);
      if (data.success) {
        this.transaction_detail = data.data;
        return;
      }

      console.log('reponse data', data);
      toast("Đã có lỗi xảy ra!", {
        "type": "error",
        "transition": "flip",
        "dangerouslyHTMLString": true
      })
    } catch (e) {
      toast("Đã có lỗi xảy ra!", {
        "type": "error",
        "transition": "flip",
        "dangerouslyHTMLString": true
      })
      console.log(e);
    }
  },
  watch: {
    //
  },
  methods: {
    FormatCurrency
    //
  },
};
</script>
