<template>
  <div>
    <div class="flex flex-wrap mt-4 table-main">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-5">
        <div class="rounded-t mb-4 px-4 py-3 border-0">
          <div class="flex flex-wrap items-center" style="justify-content: space-between;">
            <div class="relative px-4 max-w-full">
              <h3 class="font-semibold text-lg text-blueGray-700">
                Thông tin Merchant QR_Gateway
              </h3>
            </div>
          </div>
        </div>
        <div class="bg-white block w-full overflow-x-autoflex-auto px-4 lg:px-10 py-10 pt-0">
          <alert v-if="alertMessage" :message="alertMessage" :type="alertType"></alert>
          <FormMerchantQR @submit-form="handleStoreMerchant" @submit-file="handleImportMerchant"></FormMerchantQR>
        </div>
        <div class="flex flex-wrap mt-4 table-main action_button_form_fee">
          <button type="submit" form="form_config_fee" class="py-2 px-8 bg-emerald-500 hover:bg-emerald-600 text-white font-semibold rounded-lg shadow-md focus:outline-none">Lưu</button>
          <button @click="cancelAction" type="button" form="form_config_fee" class="py-2 px-8 bg-red-500 hover:bg-red-600 text-white font-semibold rounded-lg shadow-md focus:outline-none">Huỷ</button>
        </div>
        <div v-if="isLoading" class="loading absolute loading_table" style="cursor: wait;">
          <img :src="loadingImage" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.table-main table tbody {
  font-size: 22px;
  font-weight: 500;
}
</style>

<script>

import FormMerchantQR from "@/components/Tools/FormMerchantQR.vue";
import apiClient from '@/services/api.js';
import Alert from "@/components/Alerts/Alert.vue"
import loadingImage from "@/assets/img/loading.gif";
import {toast} from "vue3-toastify";

export default {
  data() {
    return {
      alertMessage: '',
      alertType: 'info',
      isLoading: false,
      loadingImage,
    };
  },
  components: {
    FormMerchantQR,
    Alert,
  },
  props: {
    //
  },
  created() {
    //
  },
  watch: {
    //
  },
  methods: {
    async handleStoreMerchant(configData) {
      this.isLoading = true;
      try {
        this.alertMessage = '';
        let response = await apiClient.post(
            'api/cms/merchant-qr',
            configData
        );
        if (response.data.success) {
          this.alertMessage = response.data.message;
          this.alertType = 'success';
          window.scroll({
            top: 10,
            left: 10,
            behavior: "smooth",
          });
          let self = this;
          setTimeout(function () {
            self.$router.push({ name: 'admin-tool-merchant-qr' });
          }, 500);
        } else {
          this.alertMessage = response?.data?.message || 'Thêm mới thất bại. Vui lòng thử lại sau!';
          this.alertType = 'error';
          window.scroll({
            top: 10,
            left: 10,
            behavior: "smooth",
          });
        }
      } catch (e) {
        console.log('Error!', e)
        this.alertMessage = e.response?.data?.message || 'Thêm mới thất bại. Vui lòng thử lại sau!';
        this.alertType = 'error';
        window.scroll({
          top: 10,
          left: 10,
          behavior: "smooth",
        });
      }
      this.isLoading = false;
    },
    async handleImportMerchant(merchantData) {
      toast("Tệp đang được tải lên. Xin vui lòng đợi", {
        "type": "warning",
        "transition": "flip",
        "dangerouslyHTMLString": true
      })
      try {
        this.isLoading = true;
        this.alertMessage = '';
        const formData = new FormData();
        formData.append('file', merchantData.file);
        let response = await apiClient.post('api/cms/merchant-qr/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data.success) {
          this.alertMessage = response.data.message;
          this.alertType = 'success';
          window.scroll({
            top: 10,
            left: 10,
            behavior: "smooth",
          });
          let self = this;
          toast("Import file thành công! Dữ liệu sẽ sớm được cập nhật", {
            "type": "success",
            "transition": "flip",
            "dangerouslyHTMLString": true
          });
          setTimeout(function () {
            self.$router.push({ name: 'admin-tool-merchant-qr' });
          }, 500);
        } else {
          this.alertMessage = response?.data?.message || 'Thêm mới thất bại. Vui lòng thử lại sau!';
          this.alertType = 'error';
          window.scroll({
            top: 10,
            left: 10,
            behavior: "smooth",
          });
        }
      } catch (e) {
        console.log('Error!', e)
        this.alertMessage = e.response?.data?.message || 'Thêm mới thất bại. Vui lòng reload lại trang và thử lại!';
        this.alertType = 'error';
        window.scroll({
          top: 10,
          left: 10,
          behavior: "smooth",
        });
      }
      this.isLoading = false;
    },
    backToList() {
      this.$router.push({ name: 'admin-tool-merchant-qr' });
    },
    cancelAction() {
      if (confirm('Thông tin sẽ không được lưu nếu bạn rời khỏi trang!')) {
        this.backToList();
      }
    },
    reload() {
      location.reload()
    }
  },
};
</script>
