import apiClient from "@/services/api";
import {toast} from "vue3-toastify";

export default function authMiddleware(next) {
    apiClient.post('/auth/verify')
        .then(response => {
            if (response?.data && response?.data?.error_code === 0) {
                return next();
            }
        })
        .catch(error => {
            toast(error?.response?.data?.message || "Đã có lỗi xảy ra!", {
                "type": "error",
                "transition": "flip",
                "dangerouslyHTMLString": true
            })
            console.error('Đã có lỗi xảy ra!', error);
            // return next("/auth/login");
        });
}
