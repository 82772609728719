<template>
  <div>
    <div v-if="activeForm" class="main-form-modals">
      <div class="relative w-auto my-6 mx-auto max-w-sm form-modals">
        <div v-if="showNotice && !messErr" class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-emerald-500"
             style="z-index: 9999;top: 28px; width: 100%; margin: auto;">
          <span class="inline-block align-middle mr-8">
            <b class="capitalize"></b>{{ messageNotice }}
          </span>
        </div>

        <div v-if="showNotice && messErr" class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500"
             style="z-index: 9999;top: 28px; width: 100%; margin: auto;">
          <span class="inline-block align-middle mr-8">
            <b class="capitalize"></b>{{ messageNotice }}
          </span>
        </div>
        <!--content của form thêm mới-->
        <div v-if="typeForm === 'create' || typeForm === 'edit'"
             class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none blur-form">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h6 v-if="typeForm === 'create'" class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Thêm mới thông tin Vai trò
            </h6>
            <h6 v-if="typeForm === 'edit'" class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Chỉnh sửa thông tin Vai trò
            </h6>
            <button
                class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                @click="toggleModal">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <div class="flex flex-wrap p-4">
                <div class="w-full px-3">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      Tên vai trò
                    </label>
                    <input type="text"
                           class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                           v-model="formData.name" placeholder="Điền tên tài khoản" style="margin-bottom: 6px;"
                           :maxlength="maxCharacters" @input="removeSpaces"/>
                    <span v-if="v$.formData.name.$error" style="color:red"> {{
                        v$.formData.name.$errors[0].$message
                      }} </span>
                  </div>
                </div>
                <div class="w-full px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      Mô tả
                    </label>
                    <input type="text"
                           class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                           v-model="formData.description" placeholder="Mô tả" style="margin-bottom: 6px;"/>
                    <span v-if="v$.formData.description.$error"
                          style="color:red"> {{ v$.formData.description.$errors[0].$message }} </span>
                  </div>
                </div>
                <div class="w-full px-4">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                      Quyền
                    </label>
                    <div v-for="permission in permissions" :key="permission.id" class="role-block p-2">
                      <div class="role-header">
                        <label class="role-label">
                          <input
                              type="checkbox"
                              v-model="permission.selected"
                          />
                          {{ permission.name }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b p-4">
            <button
                class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button" @click="toggleModal">
              Đóng
            </button>
            <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button" v-on:click="save(itemData?.id)">
              Đồng ý
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeForm" class="opacity-25 fixed inset-0 z-40 bg-black" id="small-modal-id-backdrop"></div>
  </div>
</template>
<style>
.form-modals {
  width: 100%;
  z-index: 999;
}

.main-form-modals {
  width: 36%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.blur-form {
  --tw-shadow: 0 10px 25px -3px rgb(0 0 0 / 70%), 0 4px 6px -2px rgb(0 0 0 / 0%) !important;
}

.b-out {
  background: #e3e5e7;
}
</style>
<script>
import apiClient from '@/services/api.js';
import useValidate from '@vuelidate/core'
import {helpers, minLength, required} from '@vuelidate/validators'
import {toast} from "vue3-toastify";

const alphaValidator = value => /^[a-zA-Z0-9àáảãạăắằẳẵặâấầẩẫậèéẻẽẹêếềểễệđìíỉĩịòóỏõọôốồổỗộơớờởỡợùúủũụưứừửữựỳýỷỹỵ ]+$/.test(value);
export default {
  name: "small-modal",
  components: {
  },

  data() {
    return {
      v$: useValidate(),
      showModal: false,
      myValue: '',
      formData: {
        name: '',
        description: '',
        permissions: [],
      },
      permissionIds: [],
      showNotice: false,
      messageNotice: '',
      messErr: false,
      maxCharacters: 50,
      permissions: []
    }
  },

  props: {
    activeForm: {
      type: Boolean, // kiểu dữ liệu của prop
      default: false, // giá trị mặc định nếu prop không được truyền vào
    },
    typeForm: {
      type: String, // kiểu dữ liệu của prop
      default: 'create'
    },
    itemData: {
      type: Object, // kiểu dữ liệu của prop
      default: null
    },
  },

  created() {
    this.formData.permissions = [];
    if(this.itemData){
      this.formData.name = this.itemData.name;
      this.formData.description = this.itemData.description;
      this.permissionIds = this.itemData.permissions.map(function (permission) {
        return permission.id
      })
    } else {
      this.permissionIds = [];
      this.formData.name = '';
      this.formData.description = '';
    }

    this.getPermissions()
  },

  methods: {
    removeSpaces() {
      // Loại bỏ dấu cách từ giá trị đầu vào
      this.inputText = this?.inputText.replace(/\s/g, "");
    },

    toggleModal() {
      this.$emit('update:activeForm', false);
    },

    save(idData) {
      this.v$.$validate()
      if (this.v$.$error) {
        return false;
      }// checks all inputs

      this.formData.permissions = this.permissions.filter(function (item) {
        return item.selected === true
      }).map(function (item) {return item.id})

      if (idData) {
        apiClient.put('api/permission-manager/role/update/' + idData, this.formData)
            .then(response => {
              if (response.data.error_code !== 0) {
                toast(response?.data?.message || "Đã có lỗi xảy ra!", {
                  "type": "error",
                  "transition": "flip",
                  "dangerouslyHTMLString": true
                })
              } else {
                this.messErr = false;
                this.messageNotice = response.data.message;
                this.showNotice = true;
                this.$emit('update:activeForm', false, 'create');
              }
            })
            .catch(error => {
              toast(error?.response?.data?.message || "Đã có lỗi xảy ra!", {
                "type": "error",
                "transition": "flip",
                "dangerouslyHTMLString": true
              })
              console.error('Đã có lỗi xảy ra!', error);
            });
      } else {
        apiClient.post('api/permission-manager/role', this.formData)
            .then(response => {
              if (response.data.error_code !== 0) {
                toast(response?.data?.message || "Đã có lỗi xảy ra!", {
                  "type": "error",
                  "transition": "flip",
                  "dangerouslyHTMLString": true
                })
              } else {
                this.messErr = false;
                this.messageNotice = response.data.message;
                this.showNotice = true;
                this.$emit('update:activeForm', false, 'create');
              }
            })
            .catch(error => {
              toast(error?.response?.data?.message || "Đã có lỗi xảy ra!", {
                "type": "error",
                "transition": "flip",
                "dangerouslyHTMLString": true
              })
              console.error('Đã có lỗi xảy ra!', error);
            });
      }
    },
    async getPermissions() {
      this.loading = true
      try {
        apiClient.get('api/permission-manager/permission')
            .then(response => {
              if (response.data.error_code !== 0) {
                toast(response?.data?.message || "Đã có lỗi xảy ra!", {
                  "type": "error",
                  "transition": "flip",
                  "dangerouslyHTMLString": true
                })
              }
              const permissionIds = this.permissionIds
              this.permissions = response.data.data.map(function (permission) {
                return {
                  id: permission.id,
                  selected: permissionIds.includes(permission.id),
                  name: permission.name + ' - ' + permission.description,
                }
              })

              setTimeout(() => {
                this.showNotice = false;
                this.messErr = false;
              }, 2000);
            })
            .catch(error => {
              toast(error?.response?.data?.message || "Đã có lỗi xảy ra!", {
                "type": "error",
                "transition": "flip",
                "dangerouslyHTMLString": true
              })
              console.error('Đã có lỗi xảy ra!', error);
            });
      } catch (error) {
        if (error.response?.status === 401) {
          console.error('Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!', 'error')
        } else {
          console.error('Không lấy được danh sách vị trí làm việc!', 'error')
        }
      } finally {
        this.loading = false
      }
    },
  },

  validations() {
    return {
      formData: {
        name: {
          required: helpers.withMessage('Vui lòng nhập tên vai trò', required),
          minLength: helpers.withMessage('Vui lòng nhập từ 5->255 ký tự', minLength(5)),
          alphaValidator: helpers.withMessage('Chỉ chấp nhận ký tự chữ cái', value => alphaValidator(value)),
        },
        description: {
          required: helpers.withMessage('Vui lòng nhập mô tả', required),
          minLength: helpers.withMessage('Vui lòng nhập từ 5->255 ký tự', minLength(5)),
          alphaValidator: helpers.withMessage('Chỉ chấp nhận ký tự chữ cái', value => alphaValidator(value)),
        },
      },
    }
  },

}

</script>
