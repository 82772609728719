<template>
  <form id="form_config_vas" @submit.prevent="submitForm($event)">
    <div v-if="checkPermission" class="flex flex-wrap" style="grid-row-gap: 10px;">
      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Chọn Merchant <small class="dot-red-label"></small>
          </label>
          <input
              readonly
              v-if="merchant_auth"
              :value="merchant_auth.name"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" type="text">
        </div>
      </div>
    </div>
    <div v-if="checkPermission" class="ml-3 mt-5">
      <label
          class="block uppercase text-blueGray-600 text-xs font-bold ml-5 mb-2"
      >
        Chọn nhóm dịch vụ hiển thị <small class="dot-red-label"></small>
      </label>
      <div class="flex flex-wrap" style="grid-row-gap: 10px;">
        <div class="w-full lg:w-6/12 px-4">
          <div style="align-items: center" class="relative flex w-full mb-3">
            <input v-model="config_vas.type" value="TOPUP_MOBILE" type="checkbox" style="width: 20px;height: 20px;" class="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer">
            <input
                name="group_service_name"
                readonly
                type="text"
                value="Nạp, thẻ điện thoại"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-wrap" style="grid-row-gap: 10px;">
        <div class="w-full lg:w-6/12 px-4">
          <div style="align-items: center" class="relative flex w-full mb-3">
            <input v-model="config_vas.type" type="checkbox" value="GAME_CARD" style="width: 20px;height: 20px;" class="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer">
            <input
                name="group_service_name"
                readonly
                type="text"
                value="Thẻ game"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-wrap" style="grid-row-gap: 10px;">
        <div class="w-full lg:w-6/12 px-4">
          <div style="align-items: center" class="relative flex w-full mb-3">
            <input v-model="config_vas.type" value="BILLING" type="checkbox" style="width: 20px;height: 20px;" class="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer">
            <input
                name="group_service_name"
                readonly
                type="text"
                value="Thanh toán hoá đơn"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="checkPermission" class="flex flex-wrap mt-5">
      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Hiệu lục từ <small class="dot-red-label"></small>
          </label>
          <input
              :disabled="checkDisableDate"
              v-model="config_vas.start_date"
              type="date"
              lang="vi"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          >
            Hiệu lực đến <small class="dot-red-label"></small>
          </label>
          <input
              v-model="config_vas.end_date"
              type="date"
              lang="vi"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
      <div class="w-full px-4 flex mt-3" style="grid-column-gap: 50px;">
        <div class="flex items-center gap-x-3">
          <input v-model="config_vas.status" id="rd_status_active" name="push-notifications" value="1" type="radio" class="cursor-pointer h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
          <label for="rd_status_active" class="cursor-pointer ml-2 block text-sm font-medium leading-6 text-gray-900">Hoạt Động</label>
        </div>
        <div class="flex items-center gap-x-3">
          <input v-model="config_vas.status" id="rd_status_not_active" name="push-notifications" value="0" type="radio" class="cursor-pointer h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
          <label for="rd_status_not_active" class="cursor-pointer ml-2 block text-sm font-medium leading-6 text-gray-900">Dừng hoạt động</label>
        </div>
      </div>
    </div>
  </form>
  <div v-if="isLoading" class="loading absolute loading_table" >
    <img :src="loadingImage" alt="">
  </div>
</template>
<style>
</style>
<script>

import apiClient from "@/services/api";
import moment from "moment";
import { toast } from 'vue3-toastify';
import loadingImage from "@/assets/img/loading.gif";

export default {
    data() {
      return {
        merchant_auth: [],
        selectedTypes: [],
        config_vas: {
          status: 1,
          start_date: null,
          end_date: null,
          created_by: JSON.parse(JSON.parse(localStorage.getItem('user_info'))).email ?? '',
          type: []
        },
        merchantLoading: false,
        merchantOptions: [],
        loadingImage,
        isLoading: false,
        checkDisableDate: false,
        checkPermission : true,
      };
    },
    components: {
    },
    created() {
      this.show(this.$route.params.id)
    },
    watch: {

    },
    methods: {
      async show(id) {
        try {
          this.isLoading = true;
          const { data } = await apiClient.get(this.$route.meta.detail ? 'api/config-vas/show/' + id : 'api/config-vas/edit/' + id);
          if (data && data.status) {
            if (data.data) {
              this.merchant_auth = {
                id: data.data.merchant_id,
                name: data.data.merchant_name
              };
              this.config_vas.status = data.data.status;

              this.config_vas.start_date = moment(data.data.start_date).format('YYYY-MM-DD');
              this.config_vas.end_date = moment(data.data.end_date).format('YYYY-MM-DD');
              this.config_vas.type = data.data.type;
              this.checkDisableStartDate(data.data.start_date)
              this.isLoading = false;
            }
          }

        } catch (e) {
          this.checkPermission = false;
          toast(e?.response?.data?.message || "Đã có lỗi xảy ra!", {
            "type": "error",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
          this.isLoading = false;
          console.log(e)
        }
      },

      submitForm(event) {
        console.log("sss", this.config_vas)
        event.preventDefault();

        if(this.validate()) {
          this.$emit('submit-form', {
            ...this.config_vas,
            merchant_id: this.merchant_auth.id
          })
        }
      },

      checkDisableStartDate(startDate) {
        startDate = moment(startDate).format('YYYY-MM-DD');
        let now = moment().format('YYYY-MM-DD');
        if (startDate < now) {
          this.checkDisableDate = true;
        }
      },
      validate() {
        if(!this.config_vas.type.length) {
          toast("Vui lòng chọn các dịch vụ", {
            "type": "warning",
            "transition": "flip",
            "dangerouslyHTMLString": true
          });
          return false;
        }

        if (!this.config_vas.start_date) {
          toast("Vui lòng chọn thời gian bắt đầu", {
            "type": "warning",
            "transition": "flip",
            "dangerouslyHTMLString": true
          });
          return false;
        }

        if (this.checkDisableDate === false && moment(this.config_vas.start_date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
          toast("Thời gian bắt đầu phải lớn hơn ngày hiện tại", {
            "type": "warning",
            "transition": "flip",
            "dangerouslyHTMLString": true
          });
          return false;
        }

        if (!this.config_vas.end_date) {
          toast("Vui lòng chọn thời gian kết thúc", {
            "type": "warning",
            "transition": "flip",
            "dangerouslyHTMLString": true
          });
          return false;
        }

        if (moment(this.config_vas.start_date).format('YYYY-MM-DD') > moment(this.config_vas.end_date).format('YYYY-MM-DD')) {
          toast("Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc", {
            "type": "warning",
            "transition": "flip",
            "dangerouslyHTMLString": true
          });
          return false;
        }

        return true;
      },
    },
  };
</script>
